import styled, { css } from 'styled-components'

export const HeaderWrapper = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;

  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0%);
    transition: all 0.3s ease-in-out;
  }
  .headroom--scrolled {
    position: fixed;
    transform: translateY(-150%);
    transition: all 0.3s ease-in-out;
  }
  .headroom--unpinned {
    transform: translateY(-150%);
    transition: all 0.3s ease-in-out;

    .header-box {
      padding: 20px 0;
    }

    .toggler {
      top: 15px;
    }
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
    background-color: white;
    transition: all 0.3s ease-in-out;
    ${({ isHeadroom }) =>
      isHeadroom
        ? null
        : css`
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
          `}

    .header-box {
      padding: 20px 0;
      line-height: 1;
    }

    .dropdown-content {
      top: 89%;
    }

    .toggler {
      top: 19px;
    }
  }
`
export const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.92rem 0;
  position: relative;
  transition: padding 0.3s ease-in-out;
  line-height: 1;

  ${({ hasBlog, isCustomerSuccess, theme }) =>
    hasBlog && !isCustomerSuccess
      ? `& > a::after {
    content: '';
    position: absolute;
    color: ${theme.colors.darkGrey};
    font-size: 1rem;
    line-height: 1.14rem;
    margin-left: 1.2rem;
    transform: translateY(12px);
  }`
      : null}
`

export const BlogIconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 70px;
  padding: 1rem;
  cursor: pointer;

  @media (min-width: 1200px) {
    display: none;
  }
`
