import React, { useState, useRef, useEffect} from 'react'
import { Link } from 'gatsby'

import { SearchWrapper, Close, InputWrapper, PostsWrapper, PostsList, Clear, Title } from './styles'
import { LocaleConsumer } from '../../Layout'
import { truncateString } from '../../../helper'



const Search = ({ isOpen, closeSearch, posts, locale }) => {
  const ref=useRef()
  const inputElement = useRef(null);
  const [query, setQuery] = useState('')
  const path = locale === 'en' ? `/${locale}/` : '/'

  //Function that focuses the Search bar. Delay is necessary
  useEffect(() => {
    setTimeout(() => inputElement.current.focus(), 500);
  }, [isOpen]);




  const handleOnChange = e => {
    setQuery(e.target.value)
  }

  const handleClearInput = () => {
    setQuery('')
  }

  const filteredPosts = posts.filter(({ node: { title } }) => {
    return title.toLowerCase().indexOf(query.toLowerCase()) !== -1
  })



  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <SearchWrapper isOpen={isOpen}>
          <Close onClick={closeSearch} /> 
          <PostsWrapper>
            <InputWrapper>
              <input  id='upis' type="text" value={query} onChange={handleOnChange}  placeholder={i18n.searchBlog} ref={inputElement}/>
              {query !== '' && <Clear onClick={handleClearInput} />}
            </InputWrapper>
            <PostsList>
              {filteredPosts.map(({ node: { id, title, excerpt, slug } }) => (
                <li key={id}>
                  <Link to={`${path}blog/${slug}`}>
                    <Title dangerouslySetInnerHTML={{ __html: title }} />
                  </Link>
                  <div dangerouslySetInnerHTML={{ __html: truncateString(excerpt, 200) }} />
                </li>
              ))}
            </PostsList>
          </PostsWrapper>
        </SearchWrapper>
      )}
    </LocaleConsumer>
  )
}
export default Search
