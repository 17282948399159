module.exports = {
  menuSwithcer: {
    en: 'main-menu',
    hr: 'glavni-menu'
  },

  footerMenuSwithcer: {
    en: 'footer-menu',
    hr: 'footer-izbornik'
  },

  blogMenu: {
    en: 'blog-menu',
    hr: 'blog-izbornik'
  }
}
