import styled, { css } from 'styled-components'

export const MenuTogglerWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  /* background: ${({ theme }) => theme.colors.veryDarkBlue}; */
  /* border-radius: 50%;
  box-shadow: 0px 1px 5px #00000073; */
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease-in-out, top 0.3s ease-in-out;
  z-index: 1000;
  position: fixed;
  right: 30px;
  top: ${({ isTop }) => (isTop ? '19px' : '26px')};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @media (min-width: 1200px) {
    display: none;
  }
`

export const Toggler = styled.div`
  width: 30px;
  height: 3px;

  ${({ mode, theme, open, isHeadroom }) =>
    !open && !isHeadroom
      ? css`
          background: ${mode === 'dark' ? theme.colors.veryDarkBlue : theme.colors.pureWhite};
        `
      : !isHeadroom && open
      ? css`
          background: ${theme.colors.veryDarkBlue};
        `
      : isHeadroom && !open
      ? css`
          background: ${theme.colors.veryDarkBlue};
        `
      : css`
          background: ${theme.colors.pureWhite};
        `}

  border-radius: 2px;
  transition: all 0.2s ease-in-out;
  display: flex;
  position: relative;
  transform: translateX(0px);

  &.open {
    background: transparent;
  }

  &.open::before {
    transform: rotate(45deg) translate(0px, 0px);
  }

  &.open::after {
    transform: rotate(-45deg) translate(0px, 0px);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;

    ${({ mode, theme, open, isHeadroom }) =>
      !open && !isHeadroom
        ? css`
            background: ${mode === 'dark' ? theme.colors.veryDarkBlue : theme.colors.pureWhite};
          `
        : !isHeadroom && open
        ? css`
            background: ${theme.colors.veryDarkBlue};
          `
        : isHeadroom && !open
        ? css`
            background: ${theme.colors.veryDarkBlue};
          `
        : isHeadroom && open
        ? css`
            background: ${theme.colors.veryDarkBlue};
          `
        : css`
            background: ${theme.colors.pureWhite};
          `}
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
  }
  &::before {
    transform: translateY(-8px);
  }
  &::after {
    transform: translateY(8px);
  }
`
