import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Headroom from 'react-headroom'

import { HeaderWrapper, HeaderBox, BlogIconWrapper } from './styles'

import Container from '../UI/Container'
import Navigation from '../Navigation'
import LogoWrapper from '../Logo'
import ReadProgressBar from 'components/ReadProgressBar'
import MenuToggler from '../Mobile-menu/Toggler'
import OverlayMenu from '../Mobile-menu'
import Search from '../UI/Search'
import HomeIcon from '../Navigation/Icons/home'

const Header = ({
  mode,
  inverted,
  hasBlog,
  isBlogPost,
  locale,
  isCustomerSuccess,
  posts,
  color,
  opacity,
  location,
  hasTranslation
}) => {
  const {
    menus: { edges: menus }
  } = useStaticQuery(graphql`
    query {
      menus: allWordpressWpApiMenusMenusItems {
        edges {
          node {
            id
            name
            slug
            items {
              object_id
              title
              url
              object_slug
              classes
              wordpress_children {
                title
                description
                classes
                url
                object_slug
              }
            }
          }
        }
      }
    }
  `)
  const [isIntersecting, setIntersecting] = useState(false)
  const [open, setOpen] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [isTop, setIsTop] = useState(false)
  const ref = useRef()

  const entryOptions = {
    threshold: 0.1
  }

  const useOnReveal = (ref, entryOptions) => {
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(!entry.isIntersecting)
      }, entryOptions)
      if (ref.current) {
        observer.observe(ref.current)
      }
      return () => {
        observer.disconnect()
      }
    }, [])

    return isIntersecting
  }

  useEffect(() => {
    if (open || showSearch) {
      document.body.style.overflow = 'hidden'
    }
    return () => (document.body.style.overflow = 'unset')
  }, [open, showSearch])

  const handleOverlayMenu = () => {
    setOpen(!open)
  }

  const handleOpenSearch = () => {
    setShowSearch(!showSearch)
  }

  const handleCloseSearch = () => {
    setShowSearch(false)
  }

  const showProgress = isBlogPost ? useOnReveal(ref, entryOptions) : null
  const lang = locale === 'en' ? `/${locale}/` : '/'

  return (
    <HeaderWrapper ref={ref} isHeadroom={open}>
      {showProgress && <ReadProgressBar lang={locale} />}
      {hasBlog && <Search isOpen={showSearch} closeSearch={handleCloseSearch} posts={posts} locale={locale} />}
      <OverlayMenu
        isOpen={open}
        menus={menus}
        locale={locale}
        handleOverlayMenu={handleOverlayMenu}
        isTop={isTop}
        posts={posts}
        hasBlog={hasBlog}
        location={location}
      />
      <Headroom upTolerance={2} disableInlineStyles onPin={() => setIsTop(true)} onUnfix={() => setIsTop(false)}>
        <Container>
          <MenuToggler handleOverlayMenu={handleOverlayMenu} open={open} mode={mode} isHeadroom={isTop} />
          <HeaderBox
            hasBlog={hasBlog}
            className="header-box"
            isCustomerSuccess={isCustomerSuccess === 'customer-success'}
          >
            <LogoWrapper
              mode={mode}
              isHeadroom={isTop}
              locale={locale}
              hasBlog={hasBlog}
              open={open}
              location={location}
            />
            {hasBlog && (
              <BlogIconWrapper>
                <Link to={`${lang}blog${location.search}`}>
                  <HomeIcon width={18} height={18} />
                </Link>
              </BlogIconWrapper>
            )}

            <Navigation
              mode={mode}
              isHeadroom={isTop}
              inverted={inverted}
              hasBlog={hasBlog}
              menus={menus}
              locale={locale}
              isCustomerSuccess={isCustomerSuccess}
              openSearch={handleOpenSearch}
              color={color}
              opacity={opacity}
              location={location}
              hasTranslation={hasTranslation}
            />
          </HeaderBox>
        </Container>
      </Headroom>
    </HeaderWrapper>
  )
}

Header.propTypes = {
  mode: PropTypes.string,
  inverted: PropTypes.string,
  location: PropTypes.object
}

Header.defaultProps = {
  location: {
    search: ''
  }
}

export default Header
