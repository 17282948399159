import styled, { css } from 'styled-components'

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 2rem 6rem;
  /* flex-basis: 100%; */

  @media (max-width: 1024px) {
    width: 90%;
    margin: 0 2rem;
  }
`

const animateLabel = css`
  top: 0px;
  font-size: 12px;
  color: ${({ grey, lightGrey, theme }) =>
    grey ? theme.colors.veryDarkBlue : lightGrey ? theme.colors.lightGrey : theme.colors.lightestGrey};
`

export const Selector = styled.div`
  background: none;
  background-color: ${({ transparent, theme }) => (transparent ? 'transparent' : `${theme.colors.veryDarkBlue}`)};
  color: ${({ grey, theme }) => (grey ? theme.colors.lightGrey : theme.colors.lightestGrey)};
  font-size: 1.42rem;
  line-height: 1.71rem;
  padding: 8px;
  display: block;
  width: 300px;
  height: 40px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${({ grey, theme }) => (grey ? theme.colors.lightGrey : theme.colors.veryLightGray)};
  margin: 1.78rem 0;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:focus {
    outline: none;
    color: ${({ grey, theme }) => (grey ? theme.colors.veryDarkBlue : theme.colors.lightestGrey)};
    border-bottom: 1px solid ${({ grey, theme }) => (grey ? theme.colors.veryDarkBlue : theme.colors.veryLightGray)};
  }

  &:focus ~ label {
    ${animateLabel}
  }

  svg {
    position: absolute;
    right: 0;
    transition: transform 0.2s ease-in-out;

    &.rotate {
      transform: rotate(180deg);
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`
export const Label = styled.label`
  color: ${({ lightGrey, theme }) => (lightGrey ? theme.colors.lightGrey : theme.colors.lightestGrey)};
  font-family: 'Barlow';
  font-size: 1.42rem;
  font-weight: 600;
  line-height: 1.71rem;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 30px;
  transition: 300ms ease all;

  &.shrink {
    ${animateLabel}
  }

  @media only screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`
