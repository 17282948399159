import React from 'react'

const ArrowIcon = ({ className }) => {
  return (
    <svg className={className} width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.13333 0H12L6.13333 6L0 0H6.13333Z" fill="#F7F7F7" />
    </svg>
  )
}

export default ArrowIcon
