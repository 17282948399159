import styled, { css } from 'styled-components'

export const FooterContainer = styled.footer`
  margin-bottom: 400px;
  background-color: white;
  animation: none;

  @media (max-width: 425px) {
    margin-bottom: 450px;
  }
`
export const FooterLine = styled.div`
  width: 100%;
  height: 6px;
  background: linear-gradient(270deg, #1d9ed9 5.12%, #59c9e7 49.86%, #a9cf3a 94.61%);
`
export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5rem 0;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`
export const LeftRow = styled.div`
  display: flex;
  flex: 0 1 60%;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`

export const RightRow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 30%;
`
export const LinksColumn = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    margin: 0 auto 4rem auto;
    text-align: center;
  }

  @media (max-width: 600px) {
    flex-basis: 35%;
  }
`
export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 1.28rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.6rem;
`
export const List = styled.ul`
  color: ${({ theme }) => theme.colors.darkGrey};

  li {
    margin: 0.5rem 0;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`
export const SocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 0.5rem;

  span {
    font-size: 0.85rem;
    color: ${({ theme }) => theme.colors.darkGrey};
    text-transform: uppercase;
    margin-right: 3rem;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: 5rem;
    span {
      margin-right: 0;
      margin-bottom: 3rem;
    }
  }
`
export const Icons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-basis: 50%;

  figure {
    width: 20px;
    height: 20px;
    line-height: 2rem;
  }

  @media (max-width: 1024px) {
    width: 100%;
    justify-content: space-evenly;
    figure {
      display: flex;
      justify-content: center;
      width: 35px;
      height: 35px;

      img {
        width: 100%;
      }
    }
  }
`
export const AppsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;

  @media (max-width: 1024px) {
    justify-content: space-evenly;

    svg {
      width: 130px;
    }
  }
`
export const LegalWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.veryDarkBlue};

  a:hover {
    text-decoration: underline;
  }

  span::after {
    content: ' ';
    display: inline-flex;
    vertical-align: middle;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.moderateGreen};
    width: 80px;
    margin: 0 1rem;
  }
  @media (max-width: 1024px) {
    justify-content: center;

    span::after {
      width: 40px;
    }
  }
  @media (max-width: 425px) {
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
`

export const LegalInfo = styled.div`
  display: flex;
  padding: 3rem 0;
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 0.85rem;
  font-weight: normal;
  line-height: 1.71rem;
`

export const NewsletterSection = styled.section`
  display: flex;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: -999;
  padding: 10rem 0 5rem 0;
  background-color: ${({ theme }) => theme.colors.veryDarkBlue};
  color: ${({ theme }) => theme.colors.pureWhite};

  @media (max-width: 425px) {
    padding: 10rem 0 2rem 0;
  }
`
export const NewsletterWrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    color: ${({ theme }) => theme.colors.lightestGrey};
    font-size: 1.14rem;
    margin: 0 auto 2rem auto;
    opacity: 0.6;
  }

  form {
    display: flex;

    input {
      width: 300px;
    }
  }

  @media (max-width: 1024px) {
    p {
      text-align: center;
    }

    form {
      flex-direction: column;
      align-items: center;
      padding: 0 2rem;

      input {
        width: 100%;
      }
    }
  }

  @media (max-width: 425px) {
    p {
      margin-bottom: 1rem;
      padding: 0 2rem;
    }
  }
`
export const SubmitButton = styled.button`
  align-self: center;
  border: none;
  outline: none;
  color: white;
  font-size: 1.28rem;
  font-weight: bold;
  line-height: 1.5rem;
  background-color: transparent;
  min-width: 220px;
  width: 100%;
  border: 1px solid #f7f7f7;
  border-radius: 8px;
  cursor: pointer;
  padding: 1.43rem 2rem;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  flex-basis: 50%;
  text-transform: uppercase;

  ${({ sending }) =>
    sending
      ? css`
          background-color: ${({ theme }) => theme.colors.lightestGrey};
        `
      : null}

  &:hover {
    background-color: #f7f7f7;
    color: ${({ theme }) => theme.colors.veryDarkBlue};
  }

  @media (max-width: 1024px) {
    margin-top: 2rem;
    width: 90%;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`
export const ConformationMsg = styled.div`
  display: flex;
  height: 84px;
  margin: 2rem 0;
  animation: fadeIn 0.2s ease-in-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
export const SendingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin: 0.47rem auto;

  span {
    display: inline-flex;
    width: 8px;
    height: 8px;
    margin: 0 0.3rem;
    background-color: ${({ theme }) => theme.colors.veryDarkBlue};
    border-radius: 50%;
    animation: bounce 0.4s linear infinite;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }

  @keyframes bounce {
    20% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-10px);
    }
  }
`

export const ErrorMsg = styled.div`
  color: ${({ theme }) => theme.colors.pureWhite};
  height: 22px;
  transform: translateY(-38px);
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.4s ease-in-out;
`
