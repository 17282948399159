import styled, { css } from 'styled-components'

export const ReadProgressWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 1;

  background-color: white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
  animation: fadeIn 0.4s ease-in-out;

  @keyframes fadeIn {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      transform: translateY(0);
      opacity: 1;
    }

    to {
      transform: translateY(-100%);
      opacity: 0;
    }
  }

  &.fadeIn {
    animation: fadeIn 0.4s ease-in-out;
  }

  &.fadeOut {
    animation: fadeOut 0.4s ease-in-out;
  }

  @media only screen and (max-width: 675px) {
    display: none;
  }
`
export const ReadProgressBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 0;

  p {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 1.14rem;
    line-height: 1.71rem;
    margin-right: auto;
  }

  @media only screen and (max-width: 1300px) {
    p {
      display: none;
    }
  }
`
export const FormWrapper = styled.form`
  display: flex;

  input {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-weight: 300;

    background: #ffffff;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    width: 260px;
    padding: 0 1rem;
    margin-right: 2rem;

    &::placeholder {
      color: ${({ theme }) => theme.colors.grey};
    }
    &:focus {
      outline: none;
    }
  }

  select {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-weight: 300;
    font-size: 1.14rem;
    background: #ffffff;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    width: 170px;
    margin-right: 2rem;
    outline: none;
  }
  option:first-child {
    color: red;
  }
`
export const SubscribeButton = styled.button`
  display: block;
  min-width: 200px;
  background: ${({ theme }) => theme.colors.vividBlue};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.pureWhite};
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0.5rem 3rem;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  border: none;
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.5);
  }
`

export const ProgressLineWrapper = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.veryLightGray};
  position: absolute;
  bottom: 0;
`
export const ProgressLine = styled.div.attrs((props) => ({
  style: { width: `${props.scroll}%` }
}))`
  position: absolute;
  height: 4px;
  z-index: 3;
  bottom: 0;
  background: linear-gradient(
    90deg,
    #a9cf3a 0%,
    #a7cf40 5.15%,
    #a2ce52 12.91%,
    #99cd6f 22.31%,
    #8bcb92 32.97%,
    #78cabb 44.65%,
    #59c9e7 56.32%,
    #1d9ed9 99.98%
  );
`

export const ConformationMsg = styled.div`
  display: flex;
  height: auto;

  animation: fadeIn 0.2s ease-in-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const SendingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0.47rem auto;

  span {
    display: inline-flex;
    width: 8px;
    height: 8px;
    margin: 0 0.3rem;
    background-color: #fff;
    border-radius: 50%;
    animation: bounce 0.4s linear infinite;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }

  @keyframes bounce {
    20% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-10px);
    }
  }
`
export const ErrorMsg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -37px;
  color: ${({ theme }) => theme.colors.pureWhite};
  background-color: #1d9eda;
  padding: 1rem 2rem;
  border-radius: 4px;
  height: 30px;
  z-index: 999;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.4s ease-in-out;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
`
