import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import menuArrow from 'assets/images/menu-arrow.svg'

export const OverlayMenuWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 8rem 0 0 0;
  background: ${({ theme }) => theme.colors.pureWhite};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: all 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? `translateX(0)` : `translateX(100%)`)};

  @media (min-width: 1200px) {
    display: none;
  }
`

export const NavItemUnclickable = styled.span`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-size: 1.42rem;
  font-weight: 900;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  transition: color 0.15s ease-in-out;
  padding: 1rem 1rem 0 1rem;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    right: 32px;
    top: 18px;
    width: 6px;
    height: 12px;
    background: url(${menuArrow}) no-repeat;
    background-position: center;
    transform: rotate(0deg);
    transform-origin: center;
  }

  &.active {
    padding: 1rem 1rem;
    background: #f7f7f7;
    border-radius: 10px;

    &::before {
      transform: rotate(90deg);
    }
  }
`

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  width: 100%;
  z-index: 150;
  overflow: auto;

  > li:last-child {
    color: ${({ mode, theme }) => (mode === 'light' ? theme.colors.pureWhite : theme.colors.vividBlue)};
  }

  @keyframes fadeInFromRight {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

export const NavItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const NavListItem = styled.li`
  display: block;
  position: relative;
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  margin-bottom: 1.5rem;
  line-height: 1;
  width: 100%;
  padding: 1rem 2rem 0 2rem;
  position: relative;

  ${({ unclickable }) =>
    unclickable
      ? css`
          padding: 0 1rem;
        `
      : null};
`

export const TryForFree = styled.div`
  bottom: 82px;
  a {
    margin: 1.42rem 2rem;
  }
`

export const LogIn = styled.div`
  bottom: 0;
  a {
    margin: 1.42rem 2rem;
  }
`

export const NavLink = styled(Link)`
  font-size: 1.42rem;
  font-weight: 900;
  text-decoration: none;
  padding: ${({ nopadding }) => (nopadding ? `none` : `0.5rem 1rem`)};
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.vividBlue};
  }
`

export const ExternalLink = styled.a`
  font-size: 1.8rem;
  font-weight: 900;
  text-decoration: none;
  padding: ${({ nopadding }) => (nopadding ? `none` : `0.5rem 1rem`)};
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.vividBlue};
  }

  @media screen and (max-width: 425px) {
    font-size: 1.4rem;
  }
`

export const NavChildMenu = styled.ul`
  display: flex;
  padding: 20px;
  width: 100%;
  flex-direction: column;
  transition: all 0.2s ease-in-out;

  > li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0.5rem 0;

    ${({ addMargin }) =>
      addMargin
        ? css`
            &:nth-of-type(1) {
              margin-top: 3rem;
            }

            &:nth-of-type(4) {
              margin-bottom: 3rem;
            }
          `
        : null}
  }

  li > a {
    width: 100%;
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-size: 1.14rem;
    font-weight: normal;
  }

  figure {
    width: 40px;
    margin-left: 0.8rem;
  }

  @media screen and (max-width: 425px) {
    padding: 0.2rem;
  }
`
export const Scroller = styled.div`
  position: relative;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`

export const MenuFooter = styled.div`
  position: relative;
  width: 100%;
  margin-top: 2rem;
  background: white;
`
export const CloseChildMenu = styled.button`
  display: flex;
  width: 40px;
  height: 40px;
  background: ${({ theme }) => theme.colors.veryDarkBlue};
  border-radius: 50%;
  box-shadow: 0px 1px 5px #00000073;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  z-index: 999;
  position: fixed;
  left: 30px;
  top: ${({ isTop }) => (isTop ? '15px' : '50px')};
  touch-action: auto;
  color: white;
  border: none;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: 0;

  &:focus {
    outline: none;
  }

  &.show {
    opacity: 1;
  }
`
export const MobileLangPick = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1.5rem;
  width: 100%;
  padding-left: 1rem;

  a {
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }

  .active {
    color: ${({ theme }) => theme.colors.vividBlue};
  }
`
