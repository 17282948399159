import React from 'react'

const HomeIcon = ({ className, width, height }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.853075 4.3029C0.318422 4.67715 0 5.28873 0 5.94136V12.6C0 12.9713 0.163888 13.3274 0.455612 13.59C0.747335 13.8525 1.143 14 1.55556 14H5V8.00005C5 7.44776 5.44772 7.00005 6 7.00005H8C8.55229 7.00005 9 7.44776 9 8.00005V14H12.4444C12.857 14 13.2527 13.8525 13.5444 13.59C13.8361 13.3274 14 12.9713 14 12.6V5.94136C14 5.28873 13.6816 4.67715 13.1469 4.30289L7.57346 0.401471C7.22914 0.160447 6.77086 0.160447 6.42654 0.401471L0.853075 4.3029Z"
      fill="#0D3151"
    />
  </svg>
)

const defaultProps = {
  width: 14,
  height: 14
}

HomeIcon.defaultProps = defaultProps
export default HomeIcon
