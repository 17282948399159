import styled from 'styled-components'

export const SearchWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 998;
  background: ${({ theme }) => theme.colors.pureWhite};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  left: 0px;
  top: 0;
  padding: 20px;
  transition: all 0.3s ease;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  overflow-y: auto;
`
export const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.veryDarkBlue};
  border-radius: 50%;
  padding: 1rem;
  top: 20px;
  right: 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  z-index: 1;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 1px;
    background: ${({ theme }) => theme.colors.pureWhite};
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
  }
  &::before {
    transform: rotate(45deg) translate(0px, 0px);
  }
  &::after {
    transform: rotate(-45deg) translate(0px, 0px);
  }

  &:hover {
    transform: rotate(90deg);
  }
`

export const InputWrapper = styled.div`
  width: 100%;
  margin-top: 5rem;
  position: relative;

  label {
    width: 100%;
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-weight: 900;
  }

  input {
    font-size: 1.5rem;
    margin-top: 0px;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
    padding: 0.75rem;
    outline: none;
    transition: border-bottom-color 0.2s ease-in-out;

    &:focus {
      border-bottom-color: ${({ theme }) => theme.colors.vividBlue};
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`

export const PostsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
`
export const PostsList = styled.ul`
  margin-top: 2rem;

  li {
    margin: 2rem 0;
  }
`

export const Clear = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 10px;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.veryDarkBlue};
  padding: 0.7rem;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  transform: translateY(-15px);
  cursor: pointer;
  animation: fadeIn 0.2s ease-in-out;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 1px;
    background: ${({ theme }) => theme.colors.pureWhite};
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }
  &::before {
    transform: rotate(45deg) translate(0px, 0px);
  }
  &::after {
    transform: rotate(-45deg) translate(0px, 0px);
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`
export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-size: 1.71rem;
  font-weight: bold;
  line-height: 2rem;
  transition: color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.vividBlue};
  }
`
