import React from 'react'

const AppStoreIcon = () => {
  return (
    <svg width="161" height="53" viewBox="0 0 161 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>App Store</title>
      <g clipPath="url(#clip0)">
        <path
          d="M10.4469 51.9624C10.0334 51.9624 9.63002 51.9571 9.21985 51.9479C8.37015 51.9369 7.52244 51.8631 6.68366 51.7271C5.90155 51.5927 5.14389 51.3427 4.43568 50.9852C3.73396 50.6308 3.09393 50.166 2.54012 49.6088C1.9783 49.0581 1.51055 48.4193 1.15542 47.7179C0.796375 47.0119 0.547896 46.2552 0.418636 45.4741C0.279046 44.6347 0.203523 43.786 0.192716 42.9353C0.184114 42.6497 0.172852 41.699 0.172852 41.699V10.4208C0.172852 10.4208 0.184846 9.4846 0.192784 9.20956C0.203131 8.36017 0.278216 7.5128 0.417388 6.67478C0.546886 5.89152 0.795558 5.1326 1.15479 4.42429C1.50862 3.72334 1.97377 3.08414 2.53221 2.53147C3.09003 1.97354 3.7321 1.50638 4.43501 1.14704C5.1416 0.79078 5.89782 0.542509 6.67836 0.410545C7.51989 0.273203 8.37055 0.19895 9.22319 0.188407L10.4475 0.171875H149.892L151.131 0.189071C151.975 0.199089 152.818 0.27268 153.652 0.409218C154.441 0.542838 155.205 0.792841 155.919 1.15101C157.328 1.87519 158.473 3.02091 159.196 4.4276C159.55 5.13101 159.795 5.88374 159.922 6.66024C160.063 7.50513 160.142 8.35917 160.158 9.21552C160.162 9.59897 160.162 10.0109 160.162 10.4208C160.173 10.9285 160.173 11.4118 160.173 11.8991V40.2365C160.173 40.7284 160.173 41.2084 160.162 41.6923C160.162 42.1327 160.162 42.536 160.157 42.9511C160.141 43.7922 160.064 44.631 159.925 45.4608C159.798 46.2475 159.551 47.0102 159.192 47.722C158.835 48.4158 158.37 49.049 157.814 49.5982C157.26 50.1585 156.619 50.626 155.915 50.9827C155.203 51.3428 154.44 51.5938 153.652 51.7271C152.813 51.8638 151.966 51.9376 151.116 51.9479C150.718 51.9571 150.302 51.9624 149.898 51.9624L148.427 51.9651L10.4469 51.9624Z"
          fill="#0D3151"
        />
        <path
          d="M32.5278 26.4744C32.5423 25.3443 32.8425 24.2362 33.4004 23.2533C33.9583 22.2703 34.7557 21.4445 35.7186 20.8525C35.1069 19.979 34.3 19.2601 33.3619 18.7529C32.4238 18.2458 31.3804 17.9644 30.3145 17.931C28.0408 17.6924 25.8366 19.2916 24.6779 19.2916C23.4969 19.2916 21.713 17.9547 19.792 17.9942C18.5495 18.0344 17.3386 18.3957 16.2772 19.043C15.2158 19.6903 14.3402 20.6015 13.7357 21.6878C11.1171 26.2215 13.0703 32.8847 15.5788 36.5494C16.8338 38.3439 18.3005 40.3485 20.2198 40.2774C22.0979 40.1995 22.7993 39.0798 25.0663 39.0798C27.3123 39.0798 27.9703 40.2774 29.9285 40.2322C31.9438 40.1995 33.2135 38.4297 34.4245 36.6182C35.3262 35.3395 36.0201 33.9263 36.4804 32.4309C35.3096 31.9357 34.3105 31.1068 33.6076 30.0476C32.9047 28.9884 32.5291 27.7456 32.5278 26.4744Z"
          fill="white"
        />
        <path
          d="M28.8294 15.5207C29.9282 14.2017 30.4696 12.5062 30.3385 10.7944C28.6598 10.9708 27.1091 11.7731 25.9954 13.0415C25.4509 13.6612 25.0339 14.3821 24.7682 15.1631C24.5025 15.944 24.3933 16.7697 24.4468 17.5929C25.2865 17.6015 26.1172 17.4195 26.8763 17.0606C27.6355 16.7017 28.3033 16.1752 28.8294 15.5207Z"
          fill="white"
        />
        <path
          d="M56.2682 35.7342H49.8592L48.3201 40.2788H45.6055L51.676 23.4648H54.4964L60.5669 40.2788H57.806L56.2682 35.7342ZM50.523 33.6371H55.6032L53.0988 26.2615H53.0287L50.523 33.6371Z"
          fill="white"
        />
        <path
          d="M73.6773 34.1502C73.6773 37.9597 71.6383 40.4072 68.5614 40.4072C67.782 40.4479 67.0068 40.2684 66.3247 39.8891C65.6426 39.5098 65.081 38.9461 64.7044 38.2624H64.6462V44.3343H62.1299V28.0202H64.5655V30.0591H64.6118C65.0058 29.3788 65.5768 28.818 66.2641 28.4363C66.9515 28.0547 67.7294 27.8666 68.5152 27.8919C71.6264 27.8919 73.6773 30.3513 73.6773 34.1502ZM71.0909 34.1502C71.0909 31.6683 69.8083 30.0366 67.8514 30.0366C65.9288 30.0366 64.6356 31.7027 64.6356 34.1502C64.6356 36.6202 65.9288 38.2744 67.8514 38.2744C69.8083 38.2744 71.0909 36.6546 71.0909 34.1502Z"
          fill="white"
        />
        <path
          d="M87.1695 34.1502C87.1695 37.9597 85.1305 40.4072 82.0536 40.4072C81.2742 40.4479 80.499 40.2684 79.8169 39.8891C79.1347 39.5098 78.5732 38.946 78.1966 38.2624H78.1383V44.3343H75.6221V28.0202H78.0577V30.0591H78.104C78.4979 29.3788 79.0689 28.8179 79.7563 28.4363C80.4436 28.0547 81.2215 27.8666 82.0073 27.8919C85.1186 27.8919 87.1695 30.3513 87.1695 34.1502ZM84.5831 34.1502C84.5831 31.6683 83.3005 30.0366 81.3436 30.0366C79.421 30.0366 78.1278 31.7027 78.1278 34.1502C78.1278 36.6202 79.421 38.2743 81.3436 38.2743C83.3005 38.2743 84.5831 36.6546 84.5831 34.1502H84.5831Z"
          fill="white"
        />
        <path
          d="M96.0866 35.5941C96.273 37.2615 97.8928 38.3563 100.106 38.3563C102.227 38.3563 103.753 37.2614 103.753 35.758C103.753 34.4529 102.833 33.6715 100.654 33.136L98.4746 32.611C95.3871 31.8653 93.9537 30.4213 93.9537 28.0783C93.9537 25.1772 96.4819 23.1846 100.072 23.1846C103.625 23.1846 106.06 25.1772 106.142 28.0783H103.602C103.45 26.4003 102.063 25.3875 100.036 25.3875C98.0091 25.3875 96.622 26.4122 96.622 27.9038C96.622 29.0925 97.508 29.7919 99.6752 30.3274L101.528 30.7823C104.977 31.5981 106.411 32.9839 106.411 35.4433C106.411 38.5889 103.905 40.5591 99.9198 40.5591C96.191 40.5591 93.6734 38.6352 93.5107 35.594L96.0866 35.5941Z"
          fill="white"
        />
        <path
          d="M111.842 25.1191V28.0202H114.173V30.0129H111.842V36.771C111.842 37.8208 112.309 38.3101 113.333 38.3101C113.61 38.3053 113.886 38.2858 114.161 38.2519V40.2326C113.7 40.3187 113.232 40.3577 112.763 40.349C110.282 40.349 109.314 39.4168 109.314 37.0393V30.0129H107.531V28.0202H109.314V25.1191H111.842Z"
          fill="white"
        />
        <path
          d="M115.523 34.1504C115.523 30.2933 117.795 27.8696 121.337 27.8696C124.892 27.8696 127.153 30.2933 127.153 34.1504C127.153 38.018 124.904 40.4312 121.337 40.4312C117.773 40.4312 115.523 38.018 115.523 34.1504ZM124.589 34.1504C124.589 31.5045 123.376 29.9429 121.337 29.9429C119.298 29.9429 118.087 31.5164 118.087 34.1504C118.087 36.8068 119.298 38.3565 121.337 38.3565C123.376 38.3565 124.589 36.8068 124.589 34.1504H124.589Z"
          fill="white"
        />
        <path
          d="M129.227 28.0201H131.626V30.1066H131.685C131.847 29.4549 132.229 28.879 132.766 28.4757C133.303 28.0723 133.962 27.8662 134.633 27.8918C134.923 27.8908 135.213 27.9223 135.495 27.9857V30.3393C135.129 30.2274 134.748 30.1761 134.365 30.1872C133.999 30.1724 133.635 30.2369 133.297 30.3762C132.958 30.5155 132.654 30.7263 132.405 30.9943C132.156 31.2622 131.968 31.5809 131.854 31.9284C131.739 32.276 131.701 32.6441 131.743 33.0076V40.2788H129.227L129.227 28.0201Z"
          fill="white"
        />
        <path
          d="M146.758 37.1634C146.42 39.3888 144.253 40.916 141.48 40.916C137.914 40.916 135.7 38.5267 135.7 34.6934C135.7 30.8483 137.926 28.3545 141.374 28.3545C144.766 28.3545 146.898 30.6843 146.898 34.4012V35.2633H138.24V35.4154C138.2 35.8665 138.257 36.321 138.406 36.7487C138.555 37.1763 138.794 37.5673 139.106 37.8958C139.417 38.2242 139.796 38.4825 140.215 38.6535C140.634 38.8245 141.085 38.9043 141.538 38.8877C142.133 38.9434 142.729 38.8057 143.239 38.495C143.75 38.1843 144.146 37.7173 144.369 37.1634L146.758 37.1634ZM138.252 33.5047H144.381C144.403 33.0991 144.342 32.6931 144.2 32.3124C144.059 31.9316 143.84 31.5843 143.557 31.2921C143.275 31 142.935 30.7693 142.56 30.6147C142.184 30.46 141.78 30.3847 141.374 30.3934C140.964 30.391 140.558 30.4697 140.179 30.6251C139.8 30.7804 139.455 31.0093 139.165 31.2986C138.875 31.5879 138.645 31.9317 138.488 32.3104C138.331 32.689 138.251 33.095 138.252 33.5047V33.5047Z"
          fill="white"
        />
        <path
          d="M50.2073 10.8085C50.7348 10.7706 51.2642 10.8503 51.7572 11.0418C52.2502 11.2333 52.6946 11.5319 53.0582 11.9159C53.4219 12.2999 53.6958 12.7599 53.8602 13.2626C54.0246 13.7653 54.0753 14.2982 54.0088 14.8229C54.0088 17.4039 52.6138 18.8876 50.2073 18.8876H47.2891V10.8085H50.2073ZM48.5439 17.745H50.0671C50.4441 17.7676 50.8213 17.7058 51.1714 17.5641C51.5215 17.4225 51.8355 17.2045 52.0908 16.9262C52.346 16.6479 52.5359 16.3161 52.6468 15.9551C52.7576 15.5941 52.7865 15.2129 52.7315 14.8393C52.7826 14.4672 52.7507 14.0884 52.6381 13.7301C52.5256 13.3717 52.3352 13.0427 52.0805 12.7666C51.8259 12.4904 51.5133 12.274 51.1652 12.1329C50.8172 11.9918 50.4422 11.9294 50.0671 11.9502H48.5439V17.745Z"
          fill="white"
        />
        <path
          d="M55.4267 15.8368C55.3884 15.4361 55.4342 15.0319 55.5613 14.6499C55.6883 14.268 55.8938 13.9169 56.1645 13.619C56.4352 13.3212 56.7652 13.0832 57.1333 12.9203C57.5014 12.7575 57.8995 12.6733 58.302 12.6733C58.7045 12.6733 59.1025 12.7575 59.4706 12.9203C59.8387 13.0832 60.1687 13.3212 60.4394 13.619C60.7101 13.9169 60.9156 14.268 61.0427 14.6499C61.1697 15.0319 61.2156 15.4361 61.1772 15.8368C61.2163 16.2379 61.171 16.6427 61.0443 17.0252C60.9175 17.4078 60.7122 17.7596 60.4414 18.058C60.1706 18.3565 59.8404 18.5949 59.4719 18.7581C59.1035 18.9214 58.7049 19.0057 58.302 19.0057C57.899 19.0057 57.5004 18.9214 57.132 18.7581C56.7635 18.5949 56.4333 18.3565 56.1625 18.058C55.8917 17.7596 55.6864 17.4078 55.5596 17.0252C55.4329 16.6427 55.3876 16.2379 55.4267 15.8368ZM59.9396 15.8368C59.9396 14.5152 59.3459 13.7423 58.3039 13.7423C57.258 13.7423 56.6697 14.5152 56.6697 15.8368C56.6697 17.169 57.2581 17.9359 58.3039 17.9359C59.3459 17.9359 59.9396 17.1637 59.9396 15.8368H59.9396Z"
          fill="white"
        />
        <path
          d="M68.8212 18.8875H67.573L66.3129 14.3971H66.2177L64.9628 18.8875H63.7265L62.0459 12.7905H63.2664L64.3585 17.4429H64.4485L65.702 12.7905H66.8563L68.1098 17.4429H68.205L69.2919 12.7905H70.4952L68.8212 18.8875Z"
          fill="white"
        />
        <path
          d="M71.9082 12.7907H73.0665V13.7592H73.1564C73.309 13.4114 73.5662 13.1198 73.8924 12.925C74.2185 12.7303 74.5973 12.6422 74.9759 12.673C75.2725 12.6507 75.5704 12.6954 75.8474 12.8039C76.1245 12.9123 76.3735 13.0817 76.5762 13.2995C76.7789 13.5173 76.9299 13.7779 77.0182 14.062C77.1065 14.3461 77.1297 14.6464 77.0862 14.9407V18.8876H75.8829V15.2428C75.8829 14.263 75.4572 13.7758 74.5673 13.7758C74.3659 13.7664 74.1648 13.8007 73.9779 13.8763C73.7909 13.9519 73.6226 14.067 73.4843 14.2137C73.346 14.3605 73.2411 14.5354 73.1768 14.7265C73.1124 14.9176 73.0901 15.1203 73.1115 15.3208V18.8877H71.9082L71.9082 12.7907Z"
          fill="white"
        />
        <path d="M79.0039 10.4106H80.2072V18.8877H79.0039V10.4106Z" fill="white" />
        <path
          d="M81.8798 15.8364C81.8415 15.4357 81.8873 15.0314 82.0144 14.6495C82.1415 14.2676 82.347 13.9164 82.6178 13.6185C82.8885 13.3207 83.2185 13.0827 83.5867 12.9198C83.9548 12.757 84.3529 12.6729 84.7554 12.6729C85.1579 12.6729 85.556 12.757 85.9241 12.9198C86.2922 13.0827 86.6222 13.3207 86.893 13.6185C87.1637 13.9164 87.3692 14.2676 87.4963 14.6495C87.6234 15.0314 87.6693 15.4357 87.631 15.8364C87.67 16.2375 87.6247 16.6424 87.4979 17.0249C87.3711 17.4074 87.1657 17.7592 86.8949 18.0576C86.6241 18.3561 86.2938 18.5946 85.9254 18.7577C85.5569 18.9209 85.1584 19.0053 84.7554 19.0053C84.3524 19.0053 83.9538 18.9209 83.5854 18.7577C83.2169 18.5946 82.8867 18.3561 82.6158 18.0576C82.345 17.7592 82.1396 17.4074 82.0129 17.0249C81.8861 16.6424 81.8408 16.2375 81.8798 15.8364ZM86.3927 15.8364C86.3927 14.5148 85.799 13.742 84.757 13.742C83.7111 13.742 83.1228 14.5148 83.1228 15.8364C83.1228 17.1686 83.7112 17.9355 84.757 17.9355C85.799 17.9355 86.3927 17.1633 86.3927 15.8364H86.3927Z"
          fill="white"
        />
        <path
          d="M88.8975 17.1633C88.8975 16.0658 89.7146 15.4331 91.1651 15.3432L92.8167 15.248V14.7217C92.8167 14.0778 92.3909 13.7141 91.5684 13.7141C90.8967 13.7141 90.4313 13.9607 90.2977 14.3918H89.1328C89.2558 13.3446 90.2409 12.6729 91.624 12.6729C93.1525 12.6729 94.0146 13.4338 94.0146 14.7217V18.8875H92.8563V18.0307H92.7611C92.5679 18.338 92.2965 18.5886 91.9748 18.7568C91.6531 18.9249 91.2924 19.0047 90.9298 18.988C90.6738 19.0146 90.4151 18.9873 90.1704 18.9078C89.9256 18.8283 89.7003 18.6984 89.5088 18.5265C89.3173 18.3546 89.164 18.1445 89.0587 17.9097C88.9534 17.6749 88.8985 17.4206 88.8975 17.1633ZM92.8167 16.6423V16.1326L91.3278 16.2278C90.4881 16.2839 90.1073 16.5696 90.1073 17.1071C90.1073 17.6558 90.5833 17.9751 91.2379 17.9751C91.4297 17.9945 91.6234 17.9752 91.8076 17.9182C91.9917 17.8612 92.1625 17.7677 92.3098 17.6434C92.4571 17.519 92.5779 17.3663 92.665 17.1943C92.752 17.0224 92.8036 16.8346 92.8167 16.6423Z"
          fill="white"
        />
        <path
          d="M95.5957 15.8366C95.5957 13.91 96.5861 12.6896 98.1265 12.6896C98.5076 12.672 98.8857 12.7633 99.2168 12.9527C99.5479 13.1421 99.8182 13.4219 99.9962 13.7593H100.086V10.4106H101.289V18.8877H100.136V17.9244H100.041C99.8494 18.2595 99.5697 18.5358 99.2322 18.7233C98.8947 18.9108 98.5123 19.0024 98.1265 18.9882C96.5755 18.9882 95.5957 17.7678 95.5957 15.8366ZM96.8387 15.8366C96.8387 17.1298 97.4482 17.9079 98.4677 17.9079C99.4819 17.9079 100.109 17.1185 100.109 15.8419C100.109 14.5712 99.4753 13.7705 98.4677 13.7705C97.4548 13.7705 96.8386 14.554 96.8386 15.8366H96.8387Z"
          fill="white"
        />
        <path
          d="M106.268 15.8368C106.23 15.4361 106.276 15.0319 106.403 14.6499C106.53 14.268 106.736 13.9169 107.006 13.619C107.277 13.3212 107.607 13.0832 107.975 12.9203C108.343 12.7575 108.741 12.6733 109.144 12.6733C109.546 12.6733 109.944 12.7575 110.312 12.9203C110.68 13.0832 111.01 13.3212 111.281 13.619C111.552 13.9169 111.757 14.268 111.884 14.6499C112.012 15.0319 112.057 15.4361 112.019 15.8368C112.058 16.2379 112.013 16.6427 111.886 17.0252C111.759 17.4078 111.554 17.7596 111.283 18.058C111.012 18.3565 110.682 18.5949 110.314 18.7581C109.945 18.9214 109.547 19.0057 109.144 19.0057C108.741 19.0057 108.342 18.9214 107.974 18.7581C107.605 18.5949 107.275 18.3565 107.004 18.058C106.734 17.7596 106.528 17.4078 106.401 17.0252C106.275 16.6427 106.229 16.2379 106.268 15.8368ZM110.781 15.8368C110.781 14.5152 110.188 13.7423 109.146 13.7423C108.1 13.7423 107.511 14.5152 107.511 15.8368C107.511 17.169 108.1 17.9359 109.146 17.9359C110.188 17.9359 110.781 17.1637 110.781 15.8368Z"
          fill="white"
        />
        <path
          d="M113.633 12.7907H114.791V13.7592H114.881C115.034 13.4114 115.291 13.1198 115.617 12.925C115.943 12.7303 116.322 12.6422 116.7 12.673C116.997 12.6507 117.295 12.6954 117.572 12.8039C117.849 12.9123 118.098 13.0817 118.301 13.2995C118.503 13.5173 118.655 13.7779 118.743 14.062C118.831 14.3461 118.854 14.6464 118.811 14.9407V18.8876H117.608V15.2428C117.608 14.263 117.182 13.7758 116.292 13.7758C116.09 13.7664 115.889 13.8007 115.702 13.8763C115.516 13.9519 115.347 14.067 115.209 14.2137C115.071 14.3605 114.966 14.5354 114.901 14.7265C114.837 14.9176 114.815 15.1203 114.836 15.3208V18.8877H113.633V12.7907Z"
          fill="white"
        />
        <path
          d="M125.61 11.2729V12.8187H126.931V13.8322H125.61V16.9673C125.61 17.606 125.873 17.8856 126.472 17.8856C126.625 17.8851 126.779 17.8758 126.931 17.8578V18.8601C126.715 18.8988 126.496 18.9193 126.276 18.9216C124.938 18.9216 124.405 18.4509 124.405 17.2754V13.8321H123.438V12.8186H124.405V11.2729H125.61Z"
          fill="white"
        />
        <path
          d="M128.575 10.4106H129.768V13.7705H129.863C130.023 13.4194 130.287 13.1261 130.62 12.9307C130.953 12.7353 131.338 12.6471 131.722 12.6783C132.017 12.6623 132.312 12.7116 132.586 12.8226C132.86 12.9337 133.106 13.1039 133.307 13.321C133.507 13.5381 133.657 13.7968 133.746 14.0786C133.835 14.3604 133.861 14.6584 133.822 14.9513V18.8877H132.617V15.2482C132.617 14.2743 132.164 13.7811 131.314 13.7811C131.107 13.7641 130.899 13.7926 130.704 13.8644C130.509 13.9362 130.333 14.0497 130.187 14.197C130.04 14.3442 129.928 14.5216 129.858 14.7168C129.787 14.9119 129.76 15.1201 129.779 15.3268V18.8877H128.575L128.575 10.4106Z"
          fill="white"
        />
        <path
          d="M140.838 17.2414C140.674 17.7986 140.32 18.2806 139.837 18.6031C139.354 18.9255 138.773 19.0679 138.196 19.0053C137.794 19.0159 137.395 18.9389 137.026 18.7798C136.657 18.6207 136.327 18.3832 136.059 18.0837C135.791 17.7843 135.592 17.4302 135.474 17.0459C135.357 16.6616 135.325 16.2563 135.38 15.8583C135.326 15.459 135.359 15.0529 135.476 14.6675C135.593 14.282 135.792 13.9262 136.058 13.6242C136.325 13.3221 136.653 13.0809 137.021 12.9168C137.389 12.7527 137.788 12.6695 138.191 12.673C139.887 12.673 140.911 13.832 140.911 15.7465V16.1664H136.605V16.2338C136.586 16.4576 136.615 16.6828 136.688 16.895C136.761 17.1073 136.878 17.3018 137.031 17.4663C137.184 17.6307 137.369 17.7614 137.576 17.85C137.782 17.9385 138.004 17.983 138.229 17.9805C138.517 18.015 138.808 17.9632 139.067 17.8316C139.325 17.7 139.538 17.4945 139.68 17.2413L140.838 17.2414ZM136.605 15.2765H139.685C139.7 15.0719 139.672 14.8663 139.603 14.6731C139.534 14.4798 139.425 14.3032 139.284 14.1544C139.143 14.0057 138.972 13.8882 138.782 13.8094C138.593 13.7306 138.389 13.6923 138.184 13.6971C137.976 13.6944 137.769 13.7335 137.577 13.812C137.384 13.8904 137.209 14.0067 137.062 14.1539C136.914 14.3011 136.798 14.4762 136.72 14.669C136.642 14.8618 136.603 15.0684 136.605 15.2765H136.605Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect x="0.00390625" width="160" height="52.6582" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AppStoreIcon
