import { useStaticQuery, graphql } from 'gatsby'

export const useEuVisibilityImages = () => {
  const images = useStaticQuery(graphql`
    fragment euVisibilityImage on File {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      Esif: file(relativePath: { eq: "images/visibility-eu/esif-fi-logo.jpg" }) {
        ...euVisibilityImage
      }
      Eu: file(relativePath: { eq: "images/visibility-eu/eu-amblem.jpg" }) {
        ...euVisibilityImage
      }
      Hamag: file(relativePath: { eq: "images/visibility-eu/hamag-bicro-logo.jpg" }) {
        ...euVisibilityImage
      }
      
    }
  `)

  return images
}
