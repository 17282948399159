import { useStaticQuery, graphql } from 'gatsby'

function useLanguageMapping() {
  const { allWordpressPage, allWordpressPost, allWordpressCategory } = useStaticQuery(query)

  const allPages = allWordpressPage.edges.reduce((acc, edge) => {
    return { ...acc, [edge.node.wordpress_id]: edge.node }
  }, {})

  const allPosts = allWordpressPost.edges.reduce((acc, edge) => {
    return { ...acc, [edge.node.wordpress_id]: edge.node }
  }, {})

  const allPostsCategories = allWordpressCategory.edges.reduce((acc, edge) => {
    return { ...acc, [edge.node.wordpress_id]: edge.node }
  }, {})

  const languagePagesMapping = Object.values(allPages)
  const languagePostsMapping = Object.values(allPosts)
  const languagePostsCategoriesMapping = Object.values(allPostsCategories)

  return { languagePagesMapping, languagePostsMapping, languagePostsCategoriesMapping }
}

export default useLanguageMapping

const query = graphql`
  query useLanguageMapping {
    allWordpressPage {
      edges {
        node {
          translations {
            en
            hr
          }
          slug
          path
          lang
          wordpress_id
        }
      }
    }
    allWordpressPost {
      edges {
        node {
          translations {
            en
            hr
          }
          slug
          path
          lang
          wordpress_id
        }
      }
    }
    allWordpressCategory(filter: { slug: { regex: "/^(?:(?!default).)*$/" }, wordpress_parent: { eq: 0 } }) {
      edges {
        node {
          slug
          wordpress_id
          path
          lang
          translations {
            hr
            en
          }
        }
      }
    }
  }
`
