import React from 'react'

import { MenuTogglerWrapper, Toggler } from './styles'

const MenuToggler = ({ handleOverlayMenu, open, mode, isHeadroom, ...otherProps }) => {
  return (
    <MenuTogglerWrapper onClick={handleOverlayMenu} {...otherProps} className="toggler">
      <Toggler mode={mode} open={open} isHeadroom={isHeadroom} className={open ? 'open' : ''} />
    </MenuTogglerWrapper>
  )
}

export default MenuToggler
