import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'

import { LangPickerWrapper, LangDropdown, LangDropdownItems, IconWrapper } from './styles'

import UkIcon from 'assets/images/uk-icon.svg'
import HrIcon from 'assets/images/croatia-flag.svg'
import ArrowIcon from './Icons/arrow'
import useLanguageMapping from 'graphql/useLangaugeMapping'

const language = {
  hr: 'hr',
  en: 'en'
}

const LangPicker = ({ locale, mode, isHeadroom, location }) => {
  const [show, setShow] = useState(false)
  const [selectedLang, setSelectedLang] = useState('hr')
  const { languagePagesMapping, languagePostsMapping, languagePostsCategoriesMapping } = useLanguageMapping()
  const removeTrailing = (path) => (path === `/` ? path : path.replace(/\/$/, ``))

  const handleOnClick = () => {
    setShow(!show)
  }
  // console.log(languagePostsMapping)
  const handleSelectLang = (e, lang) => {
    handleRouteChange(lang)
    setSelectedLang(lang)
  }

  const getLangIcon = (locale) => {
    if (locale === 'hr') {
      return <img src={HrIcon} alt="HR" />
    }

    if (locale === 'en') {
      return <img src={UkIcon} alt="EN" />
    }
  }

  useEffect(() => {
    setSelectedLang(locale)
  }, [locale])

  function handleRouteChange(lang) {
    const getUrl = window.location.pathname
    const getSlug = window.location.pathname.split('/').pop()

    const isBlogPost = languagePostsMapping.some((item) => {
      return item.slug === getSlug
    })

    const isBlogPostCategory = languagePostsCategoriesMapping.some((item) => {
      return item.slug === getSlug
    })

    if (isBlogPost) {
      const currentPostWpID = languagePostsMapping.find((item) => {
        return item.slug === getSlug
      })

      const getTranslationPostRoute = languagePostsMapping
        .filter((item) => item.lang === language[lang])
        .find((i) => currentPostWpID.translations[lang] === i.wordpress_id)

      const prefixPath =
        lang === 'en' ? `/en/blog/${getTranslationPostRoute.slug}` : `/blog/${getTranslationPostRoute.slug}`
      return navigate(removeTrailing(prefixPath))
    }

    if (isBlogPostCategory) {
      const currentPostCategoryWpID = languagePostsCategoriesMapping.find((item) => {
        return item.slug === getSlug
      })

      const getTranslationPostRoute = languagePostsCategoriesMapping
        .filter((item) => item.lang === language[lang])
        .find((i) => currentPostCategoryWpID.translations[lang] === i.wordpress_id)

      const prefixPath =
        lang === 'en' ? `/en/category/${getTranslationPostRoute.slug}` : `/kategorija/${getTranslationPostRoute.slug}`
      return navigate(removeTrailing(prefixPath))
    }

    const currentPageWpID = languagePagesMapping.find((item) => {
      return removeTrailing(item.path) === `${removeTrailing(getUrl)}`
    })

    const getTranslationRoute = languagePagesMapping
      .filter((item) => item.lang === language[lang])
      .find((i) => currentPageWpID.translations[lang] === i.wordpress_id)

    return navigate(removeTrailing(getTranslationRoute.path))
  }
  return (
    <LangPickerWrapper onClick={handleOnClick}>
      <IconWrapper>
        {locale === 'hr' ? <img src={HrIcon} alt="HR" /> : <img src={UkIcon} alt="UK" />}
        <ArrowIcon mode={mode} isHeadroom={isHeadroom} />
      </IconWrapper>
      <LangDropdown className="lang-dropdown">
        <span>{selectedLang}</span>
        <LangDropdownItems>
          {Object.keys(language)
            .filter((lang) => {
              return lang !== selectedLang
            })
            .map((l) => {
              return (
                <li key={l} onClick={(e) => handleSelectLang(e, language[l])}>
                  <span>{language[l]}</span>
                  {getLangIcon(l)}
                </li>
              )
            })}
        </LangDropdownItems>
      </LangDropdown>
    </LangPickerWrapper>
  )
}

LangPicker.defaultProps = {
  locale: 'hr',
  location: {
    search: ''
  }
}

export default LangPicker
