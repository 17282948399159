import { useStaticQuery, graphql } from 'gatsby'

export const useMenuImages = () => {
  const images = useStaticQuery(graphql`
    fragment MenuImages on File {
      childImageSharp {
        fluid(maxWidth: 320, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      MenuBlogImg: file(relativePath: { eq: "images/menu-blog.jpg" }) {
        ...MenuImages
      }
      MenuCustomerImg: file(relativePath: { eq: "images/menu-customer.jpg" }) {
        ...MenuImages
      }
      MenuStoryImg: file(relativePath: { eq: "images/menu-story.jpg" }) {
        ...MenuImages
      }
      MenuTeamImg: file(relativePath: { eq: "images/menu-team.jpg" }) {
        ...MenuImages
      }
    }
  `)

  return images
}
