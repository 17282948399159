import React, { useEffect, useState, useRef } from 'react'
import { LocaleConsumer } from '../Layout'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import {
  ReadProgressWrapper,
  ReadProgressBox,
  ProgressLineWrapper,
  ProgressLine,
  FormWrapper,
  SubscribeButton,
  ErrorMsg,
  ConformationMsg,
  SendingWrapper
} from './styles'

import Container from '../UI/Container'

import { useMixpanel } from 'gatsby-plugin-mixpanel'

const ReadProgressBar = ({ lang }) => {
  const [scroll, setScroll] = useState(0)

  const isComponentMounted = useRef(true)

  const listenScrollEvent = () => {
    window.addEventListener('scroll', () => {
      requestAnimationFrame(() => {
        scrollDistanceCalc()
      })
    })
  }
  const getMaxDocumentHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    )
  }
  const scrollDistanceCalc = () => {
    const scrollFromTop = window.pageYOffset
    const windowHeight = window.innerHeight
    const documentHeight = getMaxDocumentHeight()

    const scrollValue = (scrollFromTop / (documentHeight - windowHeight)) * 100

    if (isComponentMounted.current) return setScroll(scrollValue)
  }
  const [email, setEmail] = useState('')
  const [selectedIndustry, setSelect] = useState('')
  const [msg, setMsg] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)
  const [sending, setSending] = useState(false)

  const MAILCHIMPLIST = lang === 'hr' ? process.env.GATSBY_MAILCHIMP_LIST_HR : process.env.GATSBY_MAILCHIMP_LIST_EN

  const mixpanel = useMixpanel()

  const handleChange = (e) => {
    const { value } = e.target
    setEmail(value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSending(true)
    try {
      const result = await addToMailchimp(
        email,
        {
          INDUSTRY: selectedIndustry
        },
        MAILCHIMPLIST
      )

      if (result.result !== 'success') {
        setErrorMsg(result.msg)
        setSending(true)

        setTimeout(() => {
          setErrorMsg(null)
          setSending(false)
        }, 4000)

        return
      }

      setMsg(result.msg)
      mixpanel.track('newsletter_signed_up', { category: 'website', 'newsletter_category': selectedIndustry, 'location': 'header' })
      window.dataLayer && window.dataLayer.push({
        'event': 'newsletter_signed_up'
      });
    } catch (error) {
      console.log(error)
    }
    setSending(false)
  }

  const Sending = () => {
    return (
      <SendingWrapper>
        <span />
        <span />
        <span />
      </SendingWrapper>
    )
  }

  useEffect(() => {
    listenScrollEvent()

    return () => {
      isComponentMounted.current = false
      window.removeEventListener('scroll', listenScrollEvent)
    }
  }, [])

  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <ReadProgressWrapper>
          <ProgressLineWrapper>
            <ProgressLine scroll={scroll} />
          </ProgressLineWrapper>
          <Container>
            <ReadProgressBox>
              <p>{i18n.footerMsg}</p>
              {msg ? (
                <ConformationMsg>
                  <h3>{msg}</h3>
                </ConformationMsg>
              ) : (
                <>
                  <ErrorMsg show={errorMsg !== null}>{errorMsg}</ErrorMsg>
                  <FormWrapper onSubmit={handleSubmit}>
                    <input
                      type="email"
                      name={email}
                      placeholder={i18n.yourEmailPlaceholder}
                      autoComplete="off"
                      onChange={handleChange}
                      required
                    />
                    <select value={selectedIndustry} onChange={(e) => setSelect(e.target.value)}>
                      <option value="" disabled>
                        {i18n.yourIndustry}
                      </option>
                      <option value="Hotel">Hotel</option>
                      <option value="Hostel">Hostel</option>
                      <option value="Vacation Rentals">{i18n.vacationRentals}</option>
                      <option value="Other">{i18n.propertyManagers}</option>
                      <option value="Other">{i18n.other}</option>
                    </select>
                    <SubscribeButton type="submit">{sending ? <Sending /> : i18n.newsLetterSubscribe}</SubscribeButton>
                  </FormWrapper>
                </>
              )}
            </ReadProgressBox>
          </Container>
        </ReadProgressWrapper>
      )}
    </LocaleConsumer>
  )
}

export default ReadProgressBar
