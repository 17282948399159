import styled from 'styled-components'

export const LangPickerWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 0;
  cursor: pointer;
  position: relative;

  img {
    border: 2px solid ${({ theme }) => theme.colors.veryLightGray};
    border-radius: 50%;
  }

  svg {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    & .lang-dropdown {
      opacity: 1;
      visibility: visible;
    }

    svg {
      transform: rotate(180deg);
      path {
        fill: ${({ theme }) => theme.colors.lightGrey};
      }
    }
  }
`

export const IconWrapper = styled.div`
  display: flex;
  width: 39px;
  align-items: center;
  z-index: 1;

  svg {
    margin-left: 0.5rem;
  }
`

export const LangDropdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 110px;
  height: auto;
  left: -55px;
  top: -11px;
  position: absolute;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

  > span {
    display: block;
    color: #787878;
    width: 100%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    height: auto;
    line-height: 1;
    padding: 1rem;
    text-transform: uppercase;
  }
`

export const LangDropdownItems = styled.ul`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  span {
    color: #787878;
    margin: 1.5rem 1rem;
    text-transform: uppercase;
  }

  li:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  li {
    display: flex;
    width: 100%;
    align-items: center;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: ${({ theme }) => `${theme.colors.vividBlue}1f`};
    }
  }

  a {
    display: flex;
    width: 100%;
    align-items: center;
  }

  img {
    margin-left: 0.5rem;
  }
`
