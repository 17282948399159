import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { useMixpanel } from 'gatsby-plugin-mixpanel'
import { useMenuImages } from 'graphql/useMenuImagesHook'
import { handleTrackedRentlioWebappClick } from 'helper/url'
import PropTypes from 'prop-types'
import React, { useContext, useRef, useState } from 'react'
import Locales from '../../constants/locales'
import HostelIcon from '../../images/hostel-icon.png'
import HotelIcon from '../../images/hotel-icon.png'
import PropertyMangaersIcon from '../../images/property-managers-icon.png'
import VacationRentalsIcon from '../../images/vacation-rentals-icon.png'
import Button from '../Button'
import { LanguageContext } from '../Layout'
import LangPicker from '../UI/Lang-picker'
import ListArrowIcon from './Icons/arrow'
import HomeIcon from './Icons/home'
import SearchIcon from './Icons/search'
import {
  Column,
  ExternalLink,
  List,
  ListItem,
  ListWrapper,
  NavigationDropdown,
  NavigationWrapper,
  NavItemBox,
  NavItemUnclickable,
  NavLink,
  NavList,
  Row,
  SearchItem
} from './styles'

const Navigation = ({
  mode,
  inverted,
  hasBlog,
  menus,
  locale,
  isHeadroom,
  isCustomerSuccess,
  openSearch,
  color,
  opacity,
  location,
  hasTranslation
}) => {
  const { i18n } = useContext(LanguageContext)
  const [positionPseudo, setPositionPseudo] = useState()
  const navDropdown = useRef()
  const mainMenu = menus.filter(({ node }) => Locales.menuSwithcer[locale] === node.slug)
  const blogMenu = menus.filter(({ node }) => Locales.blogMenu[locale] === node.slug)
  const lang = locale === 'en' ? `/${locale}/` : '/'
  const category = locale === 'en' ? 'category' : 'kategorija'

  const handlePosition = (e) => {
    const getNavItemPosition = e.target.getBoundingClientRect()
    const getPseudoElementPosition =
      getNavItemPosition.left - navDropdown.current.getBoundingClientRect().left + getNavItemPosition.width / 6.2
    setPositionPseudo(getPseudoElementPosition)
  }

  const { MenuBlogImg, MenuStoryImg, MenuCustomerImg, MenuTeamImg } = useMenuImages()
  const mixpanel = useMixpanel()

  return (
    <NavigationWrapper mode={mode} isHeadroom={isHeadroom}>
      {!hasBlog || (hasBlog && isCustomerSuccess == 'customer-success') || isCustomerSuccess == 'price-korisnika' ? (
        <>
          <NavList mode={mode} isHeadroom={isHeadroom}>
            {mainMenu[0].node.items.map((item) => {
              if (item.classes === 'button') {
                return (
                  <li key={item.object_id}>
                    <Button
                      href={`${item.url}${location.search}`}
                      type="small"
                      mode={mode}
                      inverted={inverted}
                      isHeadroom={isHeadroom}
                      onClick={(e) =>
                        handleTrackedRentlioWebappClick(
                          e,
                          item.url,
                          location.search,
                          mixpanel,
                          'try_free_button_clicked',
                          'nav_menu',
                          true,
                          true,
                          true
                        )
                      }
                    >
                      {item.title}
                    </Button>
                  </li>
                )
              }

              if (item.classes === 'login') {
                return (
                  <li key={item.object_id}>
                    <ExternalLink
                      color={color}
                      opacity={opacity}
                      href={`${item.url}${location.search}`}
                      onClick={(e) =>
                        handleTrackedRentlioWebappClick(
                          e,
                          item.url,
                          location.search,
                          mixpanel,
                          'log_in_clicked',
                          'nav_menu',
                          true,
                          true,
                          true
                        )
                      }
                    >
                      {item.title}
                    </ExternalLink>
                  </li>
                )
              }
              return (
                <li key={item.object_id}>
                  {item.classes === 'unclickable' ? (
                    <NavItemUnclickable onMouseOver={handlePosition}>{item.title}</NavItemUnclickable>
                  ) : (
                    <NavLink
                      nopadding="true"
                      color={color}
                      opacity={opacity}
                      to={`${lang}${item.object_slug}${location.search}`}
                    >
                      {item.title}
                    </NavLink>
                  )}
                  {(item.wordpress_children && item.object_slug === 'prednosti') || item.object_slug === 'features' ? (
                    <NavigationDropdown className="dropdown-content" ref={navDropdown} position={positionPseudo}>
                      <Column width="75">
                        <Row margin="4">
                          <NavItemBox align="center" direction="row" width="66">
                            <NavLink
                              to={`${lang}${item.wordpress_children[0].object_slug}${location.search}`}
                              nopadding="true"
                              onClick={(e) =>
                                handleTrackedRentlioWebappClick(
                                  e,
                                  '',
                                  '',
                                  mixpanel,
                                  'user_segment_clicked',
                                  'nav_menu',
                                  false,
                                  false,
                                  false,
                                  'hotel'
                                )
                              }
                            >
                              <figure>
                                <img src={HotelIcon} alt="" />
                              </figure>
                              <div>
                                <h6>{item.wordpress_children[0].title}</h6>
                                <span>{item.wordpress_children[0].description}</span>
                              </div>
                            </NavLink>
                          </NavItemBox>
                          <NavItemBox align="center" direction="row" width="66">
                            <NavLink
                              to={`${lang}${item.wordpress_children[2].object_slug}${location.search}`}
                              nopadding="true"
                              onClick={(e) =>
                                handleTrackedRentlioWebappClick(
                                  e,
                                  '',
                                  '',
                                  mixpanel,
                                  'user_segment_clicked',
                                  'nav_menu',
                                  false,
                                  false,
                                  false,
                                  'vacation_rentals'
                                )
                              }
                            >
                              <figure>
                                <img src={VacationRentalsIcon} alt="" />
                              </figure>
                              <div>
                                <h6>{item.wordpress_children[2].title}</h6>
                                <span>{item.wordpress_children[2].description}</span>
                              </div>
                            </NavLink>
                          </NavItemBox>
                        </Row>
                        <Row margin="4">
                          <NavItemBox align="center" direction="row" width="66">
                            <NavLink
                              to={`${lang}${item.wordpress_children[1].object_slug}${location.search}`}
                              nopadding="true"
                              onClick={(e) =>
                                handleTrackedRentlioWebappClick(
                                  e,
                                  '',
                                  '',
                                  mixpanel,
                                  'user_segment_clicked',
                                  'nav_menu',
                                  false,
                                  false,
                                  false,
                                  'hostel'
                                )
                              }
                            >
                              <figure>
                                <img src={HostelIcon} alt="" />
                              </figure>
                              <div>
                                <h6>{item.wordpress_children[1].title}</h6>
                                <span>{item.wordpress_children[1].description}</span>
                              </div>
                            </NavLink>
                          </NavItemBox>
                          <NavItemBox align="center" direction="row" width="66">
                            <NavLink
                              to={`${lang}${item.wordpress_children[3].object_slug}${location.search}`}
                              nopadding="true"
                              onClick={(e) =>
                                handleTrackedRentlioWebappClick(
                                  e,
                                  '',
                                  '',
                                  mixpanel,
                                  'user_segment_clicked',
                                  'nav_menu',
                                  false,
                                  false,
                                  false,
                                  'property_managers'
                                )
                              }
                            >
                              <figure>
                                <img src={PropertyMangaersIcon} alt="" />
                              </figure>
                              <div>
                                <h6>{item.wordpress_children[3].title}</h6>
                                <span>{item.wordpress_children[3].description}</span>
                              </div>
                            </NavLink>
                          </NavItemBox>
                        </Row>
                      </Column>
                      <Column width="25">
                        <ListWrapper>
                          <List>
                            {item.wordpress_children.slice(4).map((item, i) => {
                              if (item.object_slug === 'property-management-system') {
                                return (
                                  <ListItem key={`${item.object_slug}-${i}`}>
                                    <ListArrowIcon />
                                    <NavLink
                                      to={`${lang}${item.object_slug}${location.search}`}
                                      activeClassName=""
                                      nopadding="true"
                                      onClick={(e) =>
                                        handleTrackedRentlioWebappClick(
                                          e,
                                          '',
                                          '',
                                          mixpanel,
                                          'feature_clicked',
                                          'nav_menu',
                                          false,
                                          true,
                                          false,
                                          '',
                                          'pms'
                                        )
                                      }
                                    >
                                      {item.title}
                                    </NavLink>
                                  </ListItem>
                                )
                              }
                              if (
                                item.object_slug === 'channel-manager' ||
                                item.object_slug === 'channel-manager-hrvatska'
                              ) {
                                return (
                                  <ListItem key={`${item.object_slug}-${i}`}>
                                    <ListArrowIcon />
                                    <NavLink
                                      to={`${lang}${item.object_slug}${location.search}`}
                                      activeClassName=""
                                      nopadding="true"
                                      onClick={(e) =>
                                        handleTrackedRentlioWebappClick(
                                          e,
                                          '',
                                          '',
                                          mixpanel,
                                          'feature_clicked',
                                          'nav_menu',
                                          false,
                                          false,
                                          false,
                                          '',
                                          'channel_manager'
                                        )
                                      }
                                    >
                                      {item.title}
                                    </NavLink>
                                  </ListItem>
                                )
                              }
                              if (item.object_slug === 'mobilna-aplikacija' || item.object_slug === 'mobile-apps') {
                                return (
                                  <ListItem key={`${item.object_slug}-${i}`}>
                                    <ListArrowIcon />
                                    <NavLink
                                      to={`${lang}${item.object_slug}${location.search}`}
                                      activeClassName=""
                                      nopadding="true"
                                      onClick={(e) =>
                                        handleTrackedRentlioWebappClick(
                                          e,
                                          '',
                                          '',
                                          mixpanel,
                                          'feature_clicked',
                                          'nav_menu',
                                          false,
                                          false,
                                          false,
                                          '',
                                          'mobile_app'
                                        )
                                      }
                                    >
                                      {item.title}
                                    </NavLink>
                                  </ListItem>
                                )
                              }
                              if (item.object_slug === 'booking-engine') {
                                return (
                                  <ListItem key={`${item.object_slug}-${i}`}>
                                    <ListArrowIcon />
                                    <NavLink
                                      to={`${lang}${item.object_slug}${location.search}`}
                                      activeClassName=""
                                      nopadding="true"
                                      onClick={(e) =>
                                        handleTrackedRentlioWebappClick(
                                          e,
                                          '',
                                          '',
                                          mixpanel,
                                          'feature_clicked',
                                          'nav_menu',
                                          false,
                                          false,
                                          false,
                                          '',
                                          'booking_engine'
                                        )
                                      }
                                    >
                                      {item.title}
                                    </NavLink>
                                  </ListItem>
                                )
                              }
                              return (
                                <ListItem key={`${item.object_slug}-${i}`}>
                                  <ListArrowIcon />
                                  <NavLink
                                    to={`${lang}${item.object_slug}${location.search}`}
                                    activeClassName=""
                                    nopadding="true"
                                  >
                                    {item.title}
                                  </NavLink>
                                </ListItem>
                              )
                            })}
                          </List>
                        </ListWrapper>
                      </Column>
                    </NavigationDropdown>
                  ) : null}
                  {(item.wordpress_children && item.object_slug === 'savjeti') || item.object_slug === 'resources' ? (
                    <NavigationDropdown className="dropdown-content" ref={navDropdown} position={positionPseudo}>
                      <Column width="75">
                        <Row margin="4">
                          <NavItemBox align="flex-start" direction="column" width="320">
                            <NavLink
                              to={`${lang}${item.wordpress_children[0].object_slug}${location.search}`}
                              activeClassName=""
                              nopadding="true"
                              align="flex-start"
                              direction="column"
                              onClick={(e) =>
                                handleTrackedRentlioWebappClick(
                                  e,
                                  '',
                                  '',
                                  mixpanel,
                                  'blog_section_clicked',
                                  'nav_menu',
                                  false,
                                  true
                                )
                              }
                            >
                              <figure>
                                <Img fluid={MenuBlogImg.childImageSharp.fluid} alt="Menu Blog" />
                              </figure>
                              <div>
                                <h6>{item.wordpress_children[0].title}</h6>
                                <span>{item.wordpress_children[0].description}</span>
                              </div>
                            </NavLink>
                          </NavItemBox>
                          <NavItemBox align="flex-start" direction="column" width="320">
                            <NavLink
                              to={`${lang}${category}${item.wordpress_children[1].url}${location.search}`}
                              activeClassName=""
                              nopadding="true"
                              align="flex-start"
                              direction="column"
                              onClick={(e) =>
                                handleTrackedRentlioWebappClick(
                                  e,
                                  '',
                                  '',
                                  mixpanel,
                                  'case_study_clicked',
                                  'nav_menu',
                                  false,
                                  true
                                )
                              }
                            >
                              <figure>
                                <Img fluid={MenuCustomerImg.childImageSharp.fluid} alt="Customer Success" />
                              </figure>
                              <div>
                                <h6>{item.wordpress_children[1].title}</h6>
                                <span>{item.wordpress_children[1].description}</span>
                              </div>
                            </NavLink>
                          </NavItemBox>
                        </Row>
                      </Column>
                      <Column width="25">
                        <ListWrapper>
                          <List>
                            {item.wordpress_children.slice(2).map((item, i) => {
                              if (item.classes === 'help') {
                                return (
                                  <ListItem key={`${item.object_slug}-${i}`}>
                                    <ListArrowIcon />
                                    <ExternalLink
                                      href={`${item.url}${location.search}`}
                                      target="__blank"
                                      nopadding="true"
                                    >
                                      {item.title}
                                    </ExternalLink>
                                  </ListItem>
                                )
                              }

                              if (item.classes === 'api') {
                                return (
                                  <ListItem key={`${item.object_slug}-${i}`}>
                                    <ListArrowIcon />
                                    <ExternalLink
                                      href={`${item.url}${location.search}`}
                                      target="__blank"
                                      nopadding="true"
                                    >
                                      {item.title}
                                    </ExternalLink>
                                  </ListItem>
                                )
                              }
                              return (
                                <ListItem key={`${item.object_slug}-${i}`}>
                                  <ListArrowIcon />
                                  <NavLink to={`${lang}${item.object_slug}${location.search}`} nopadding="true">
                                    {item.title}
                                  </NavLink>
                                </ListItem>
                              )
                            })}
                          </List>
                        </ListWrapper>
                      </Column>
                    </NavigationDropdown>
                  ) : null}
                  {(item.wordpress_children && item.object_slug === 'o-nama') || item.object_slug === 'about-us' ? (
                    <NavigationDropdown className="dropdown-content" ref={navDropdown} position={positionPseudo}>
                      <Column width="75">
                        <Row margin="4">
                          <NavItemBox align="flex-start" direction="column" width="320">
                            <NavLink
                              to={`${lang}${item.wordpress_children[0].object_slug}${location.search}`}
                              activeClassName=""
                              nopadding="true"
                              align="flex-start"
                              direction="column"
                            >
                              <figure>
                                <Img fluid={MenuTeamImg.childImageSharp.fluid} alt="Rentlio Team" />
                              </figure>
                              <div>
                                <h6>{item.wordpress_children[0].title}</h6>
                                <span>{item.wordpress_children[0].description}</span>
                              </div>
                            </NavLink>
                          </NavItemBox>
                          <NavItemBox align="flex-start" direction="column" width="320">
                            <NavLink
                              to={`${lang}${item.wordpress_children[1].object_slug}${location.search}`}
                              activeClassName=""
                              nopadding="true"
                              align="flex-start"
                              direction="column"
                            >
                              <figure>
                                <Img fluid={MenuStoryImg.childImageSharp.fluid} alt="Story" />
                              </figure>
                              <div>
                                <h6>{item.wordpress_children[1].title}</h6>
                                <span>{item.wordpress_children[1].description}</span>
                              </div>
                            </NavLink>
                          </NavItemBox>
                        </Row>
                      </Column>
                      <Column width="25">
                        <ListWrapper>
                          <List>
                            {item.wordpress_children.slice(2).map((item, i) => {
                              if (item.classes === 'year') {
                                return (
                                  <ListItem key={`${item.object_slug}-${i}`}>
                                    <ListArrowIcon />
                                    <ExternalLink href={item.url} nopadding="true" target="__blank">
                                      {item.title}
                                    </ExternalLink>
                                  </ListItem>
                                )
                              }
                              return (
                                <ListItem key={`${item.object_slug}-${i}`}>
                                  <ListArrowIcon />
                                  <NavLink
                                    to={`${lang}${item.object_slug}${location.search}`}
                                    activeClassName=""
                                    nopadding="true"
                                  >
                                    {item.title}
                                  </NavLink>
                                </ListItem>
                              )
                            })}
                          </List>
                        </ListWrapper>
                      </Column>
                    </NavigationDropdown>
                  ) : null}
                </li>
              )
            })}
          </NavList>
          {!hasTranslation ? (
            <LangPicker locale={locale} mode={mode} isHeadroom={isHeadroom} location={location} />
          ) : null}
        </>
      ) : (
        <>
          <NavList mode={mode}>
            {blogMenu[0].node.items.map((item) => {
              let url = locale === 'hr' ? item.url.replace('category', 'kategorija') : item.url

              if (item.classes === 'blog') {
                return (
                  <li key={item.object_id}>
                    <NavLink
                      color={color}
                      opacity={opacity}
                      nopadding="true"
                      to={`${lang}blog${location.search}`}
                      aria-label="Blog"
                    >
                      <HomeIcon />
                    </NavLink>
                  </li>
                )
              }
              return (
                <li key={item.object_id}>
                  <NavLink
                    color={color}
                    opacity={opacity}
                    nopadding="true"
                    to={`${url.replace(/\/$/, ``)}${location.search}`}
                  >
                    {item.title}
                  </NavLink>
                </li>
              )
            })}
            <SearchItem onClick={openSearch}>
              {i18n.search}
              <SearchIcon className="search-icon" />
            </SearchItem>
            <LangPicker locale={locale} mode={mode} isHeadroom={isHeadroom} location={location} />
          </NavList>
        </>
      )}
    </NavigationWrapper>
  )
}

Navigation.propTypes = {
  mode: PropTypes.string,
  inverted: PropTypes.string,
  hasBlog: PropTypes.bool,
  menus: PropTypes.arrayOf(PropTypes.object),
  locale: PropTypes.string,
  location: PropTypes.object
}

Navigation.defaultProps = {
  location: {
    search: ''
  }
}

export default Navigation
