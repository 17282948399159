import React from 'react'

const ListArrowIcon = ({ width, height }) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none">
      <path
        d="M3.332 8h9.333M8 3.333L12.667 8 8 12.667"
        stroke="#0D3151"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

ListArrowIcon.defaultProps = {
  width: 16,
  height: 16
}

export default ListArrowIcon
