import React, { useState } from 'react'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import PropTypes from 'prop-types'
import { handleTrackedRentlioWebappClick } from 'helper/url'
import { useMixpanel } from 'gatsby-plugin-mixpanel'


import { LocaleConsumer } from '../Layout'
import Locales from '../../constants/locales'

import {
  FooterContainer,
  FooterLine,
  FooterWrapper,
  LeftRow,
  RightRow,
  LinksColumn,
  Title,
  List,
  SocialWrapper,
  Icons,
  AppsWrapper,
  LegalWrapper,
  LegalInfo,
  NewsletterSection,
  NewsletterWrapper,
  SubmitButton,
  SendingWrapper,
  ErrorMsg,
  ConformationMsg
} from './styles'

import FormInput from '../Form-input'
import Container from '../UI/Container'

import Facebook from '../../images/facebook-icon.svg'
import Instagram from '../../images/instagram-icon.svg'
import Twitter from '../../images/twitter-icon.svg'
import YouTube from '../../images/youtube-icon.svg'
import LinkedIn from '../../images/linkedin-icon.svg'

import { AppStoreIcon, GooglePlayIcon } from '../Store-icons'
import Select from '../UI/Select'
import EuVisibility from './EU-visibility'

const Footer = ({ locale, legalinfo, location }) => {
  const {
    menus: { edges: menus }
  } = useStaticQuery(graphql`
    query {
      menus: allWordpressWpApiMenusMenusItems {
        edges {
          node {
            id
            name
            slug
            items {
              object_id
              title
              url
              object_slug
              classes
              wordpress_children {
                title
                url
                object_slug
                classes
              }
            }
          }
        }
      }
    }
  `)

  const [email, setEmail] = useState('')
  const [selectedIndustry, setSelect] = useState('')
  const [msg, setMsg] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)
  const [sending, setSending] = useState(false)

  const lang = locale === 'en' ? `/${locale}/` : '/'
  const MAILCHIMPLIST = locale === 'hr' ? process.env.GATSBY_MAILCHIMP_LIST_HR : process.env.GATSBY_MAILCHIMP_LIST_EN
  const mixpanel = useMixpanel()

  const handleChange = (e) => {
    const { value } = e.target
    setEmail(value)
  }

  const onSelectedIndustry = (item) => {
    setSelect(item)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSending(true)
    try {
      const result = await addToMailchimp(
        email,
        {
          INDUSTRY: selectedIndustry
        },
        MAILCHIMPLIST
      )

      if (result.result !== 'success') {
        setErrorMsg(result.msg)
        setSending(true)

        setTimeout(() => {
          setErrorMsg(null)
          setSending(false)
        }, 4000)

        return
      }

      setMsg(result.msg)
      mixpanel.track('newsletter_signed_up', { category: 'website', 'newsletter_category': selectedIndustry, 'location': 'footer' })
      window.dataLayer && window.dataLayer.push({
        'event': 'newsletter_signed_up'
      });
    } catch (error) {
      console.log(error)
    }
    setSending(false)
  }

  const Sending = () => {
    return (
      <SendingWrapper>
        <span />
        <span />
        <span />
      </SendingWrapper>
    )
  }
  

  const footerMenu = menus.filter(({ node }) => Locales.footerMenuSwithcer[locale] === node.slug)

  const category = locale === 'en' ? 'category' : 'kategorija'
  const privacy = locale === 'en' ? `/${locale}/privacy-policy` : '/izjava-o-privatnosti'
  const terms = locale === 'en' ? `/${locale}/terms` : '/uvjeti'
  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <FooterContainer>
          <Container>
            <FooterLine />
            <FooterWrapper>
              <LeftRow>
                {footerMenu[0].node.items.map((item) => {
                  return (
                    <LinksColumn key={item.object_id}>
                      <Title>{item.title}</Title>
                      {item.wordpress_children ? (
                        <List>
                          {item.wordpress_children.map((childItem, i) => {
                            if (childItem.classes === 'customer-success') {
                              return (
                                <li key={`${i}-${childItem.object_slug}`}>
                                  <Link to={`${lang}${category}${childItem.url}${location.search}`} onClick={(e) => handleTrackedRentlioWebappClick(e, '', '', mixpanel, 'case_study_clicked', 'footer', false, true)}>
                                    {childItem.title}
                                  </Link>
                                </li>
                              )
                            }

                            if (childItem.classes === 'api') {
                              return (
                                <li key={`${i}-${childItem.object_slug}`}>
                                  <a href={`${childItem.url}${location.search}`} nopadding="true" target="__blank">
                                    {childItem.title}
                                  </a>
                                </li>
                              )
                            }

                            if (childItem.classes === 'help') {
                              return (
                                <li key={`${i}-${childItem.object_slug}`}>
                                  <a href={`${childItem.url}${location.search}`} nopadding="true" target="__blank">
                                    {childItem.title}
                                  </a>
                                </li>
                              )
                            }

                            if (childItem.classes === 'year') {
                              return (
                                <li key={`${i}-${childItem.object_slug}`}>
                                  <a href={`${childItem.url}${location.search}`} nopadding="true" target="__blank">
                                    {childItem.title}
                                  </a>
                                </li>
                              )
                            }

                            if (childItem.object_slug === 'blog') {
                              return (
                                <li key={`${i}-${childItem.object_slug}`}>
                                  <Link to={`${lang}${childItem.object_slug}${location.search}`} onClick={(e) => handleTrackedRentlioWebappClick(e, '', '', mixpanel, 'blog_section_clicked', 'footer', false, true)}>{childItem.title}</Link>
                                </li>
                              )
                            }

                            if(childItem.object_slug === 'vacation-rentals' || childItem.object_slug === 'mali-iznajmljivaci') {
                              return (
                                <li key={`${i}-${childItem.object_slug}`}>
                                  <Link to={`${lang}${childItem.object_slug}${location.search}`} onClick={(e) => handleTrackedRentlioWebappClick(e, '', '', mixpanel, 'user_segment_clicked', 'footer', false, true, false, 'vacation_rentals')}>{childItem.title}</Link>
                                </li>
                              )
                            }

                            if(childItem.object_slug === 'property-managers' || childItem.object_slug === 'property-manageri') {
                              return (
                                <li key={`${i}-${childItem.object_slug}`}>
                                  <Link to={`${lang}${childItem.object_slug}${location.search}`} onClick={(e) => handleTrackedRentlioWebappClick(e, '', '', mixpanel, 'user_segment_clicked', 'footer', false, true, false, 'property_managers')}>{childItem.title}</Link>
                                </li>
                              )
                            }

                            if(childItem.object_slug === 'hotel') {
                              return (
                                <li key={`${i}-${childItem.object_slug}`}>
                                  <Link to={`${lang}${childItem.object_slug}${location.search}`} onClick={(e) => handleTrackedRentlioWebappClick(e, '', '', mixpanel, 'user_segment_clicked', 'footer', false, true, false, 'hotel')}>{childItem.title}</Link>
                                </li>
                              )
                            }

                            if(childItem.object_slug === 'hostel') {
                              return (
                                <li key={`${i}-${childItem.object_slug}`}>
                                  <Link to={`${lang}${childItem.object_slug}${location.search}`} onClick={(e) => handleTrackedRentlioWebappClick(e, '', '', mixpanel, 'user_segment_clicked', 'footer', false, true, false, 'hostel')}>{childItem.title}</Link>
                                </li>
                              )
                            }
                            
                            if(childItem.object_slug === "property-management-system") {
                              return (
                                <li key={`${i}-${childItem.object_slug}`}>
                                  <Link to={`${lang}${childItem.object_slug}${location.search}`} onClick={(e) => handleTrackedRentlioWebappClick(e, '', '', mixpanel, 'feature_clicked', 'footer', false, true, false, '', 'pms')}>{childItem.title}</Link>
                                </li>
                              )
                            }

                            if(childItem.object_slug === "channel-manager" || childItem.object_slug === "channel-manager-hrvatska") {
                              return (
                                <li key={`${i}-${childItem.object_slug}`}>
                                  <Link to={`${lang}${childItem.object_slug}${location.search}`} onClick={(e) => handleTrackedRentlioWebappClick(e, '', '', mixpanel, 'feature_clicked', 'footer', false, true, false, '', 'channel_manager')}>{childItem.title}</Link>
                                </li>
                              )
                            }

                            if(childItem.object_slug === "mobilna-aplikacija" || childItem.object_slug === "mobile-apps") {
                              return (
                                <li key={`${i}-${childItem.object_slug}`}>
                                  <Link to={`${lang}${childItem.object_slug}${location.search}`} onClick={(e) => handleTrackedRentlioWebappClick(e, '', '', mixpanel, 'feature_clicked', 'footer', false, true, false, '', 'mobile_app')}>{childItem.title}</Link>
                                </li>
                              )
                            }

                            if(childItem.object_slug === "booking-engine") {
                              return (
                                <li key={`${i}-${childItem.object_slug}`}>
                                  <Link to={`${lang}${childItem.object_slug}${location.search}`} onClick={(e) => handleTrackedRentlioWebappClick(e, '', '', mixpanel, 'feature_clicked', 'footer', false, true, false, '', 'booking_engine')}>{childItem.title}</Link>
                                </li>
                              )
                            }

                            return (
                              <li key={`${i}-${childItem.object_slug}`}>
                                <Link to={`${lang}${childItem.object_slug}${location.search}`}>{childItem.title}</Link>
                              </li>
                            )
                          })}
                        </List>
                      ) : null}
                    </LinksColumn>
                  )
                })}
              </LeftRow>
              <RightRow>
                <SocialWrapper>
                  <span>{i18n.follow}</span>
                  <Icons>
                    <a href="https://www.facebook.com/Rentlio" target="_blank" rel="noreferrer">
                      <figure>
                        <img src={Facebook} alt="Facebook" />
                      </figure>
                    </a>

                    <a href="https://www.instagram.com/rentlio/" target="_blank" rel="noreferrer">
                      <figure>
                        <img src={Instagram} alt="Instagram" />
                      </figure>
                    </a>

                    <a href="https://twitter.com/rentlio" target="_blank" rel="noreferrer">
                      <figure>
                        <img src={Twitter} alt="Twitter" />
                      </figure>
                    </a>

                    <a href="https://www.youtube.com/channel/UCxe9C197-bFD13nY86XbrEA" target="_blank" rel="noreferrer">
                      <figure>
                        <img src={YouTube} alt="YouTube" />
                      </figure>
                    </a>

                    <a href="https://www.linkedin.com/company/rentlio/" target="_blank" rel="noreferrer">
                      <figure>
                        <img src={LinkedIn} alt="LinkedIn" />
                      </figure>
                    </a>
                  </Icons>
                </SocialWrapper>
                <AppsWrapper>
                  <a
                    href={
                      locale === 'hr'
                        ? 'https://apps.apple.com/hr/app/rentlio/id1122008618?l=hr'
                        : 'https://apps.apple.com/hr/app/rentlio/id1122008618'
                    }
                    rel="noreferrer"
                    onClick={(e) =>
                      handleTrackedRentlioWebappClick(
                        e,
                        locale === 'hr'
                        ? 'https://apps.apple.com/hr/app/rentlio/id1122008618?l=hr'
                        : 'https://apps.apple.com/hr/app/rentlio/id1122008618',
                        '',
                        mixpanel,
                        'app_store_download_clicked',
                        'footer',
                        true,
                        true,
                        true
                      )
                    }
                  >
                    <figure>
                      <AppStoreIcon />
                    </figure>
                  </a>
                  <a
                    href={
                      locale === 'hr'
                        ? 'https://play.google.com/store/apps/details?id=com.rentlio.app&hl=hr'
                        : 'https://play.google.com/store/apps/details?id=com.rentlio.app&hl=en'
                    }
                    rel="noreferrer"
                    onClick={(e) =>
                      handleTrackedRentlioWebappClick(
                        e,
                        locale === 'hr'
                        ? 'https://play.google.com/store/apps/details?id=com.rentlio.app&hl=hr'
                        : 'https://play.google.com/store/apps/details?id=com.rentlio.app&hl=en',
                        '',
                        mixpanel,
                        'google_play_download_clicked',
                        'footer',
                        true,
                        true,
                        true
                      )
                    }
                  >
                    <figure>
                      <GooglePlayIcon />
                    </figure>
                  </a>
                </AppsWrapper>
                <LegalWrapper>
                  <Link to={`${privacy}${location.search}`}>{i18n.privacyPolicy}</Link>
                  <span />
                  <Link to={`${terms}${location.search}`}>{i18n.termsCondition}</Link>
                </LegalWrapper>
              </RightRow>
            </FooterWrapper>
            {legalinfo && (
              <LegalInfo>
                <p>{i18n.legalInfo}</p>
              </LegalInfo>
            )}
            {locale === 'hr' && <EuVisibility />}
          </Container>
          <NewsletterSection>
            <Container addMargin>
              <NewsletterWrapper>
                <p>{i18n.footerMsg}</p>
                {msg ? (
                  <ConformationMsg>
                    <h3>{msg}</h3>
                  </ConformationMsg>
                ) : (
                  <>
                    <form onSubmit={handleSubmit}>
                      <FormInput
                        type="email"
                        name="subscriber-email"
                        value={email}
                        handleChange={handleChange}
                        label={i18n.yourEmail}
                        required
                        autoComplete="off"
                      />
                      <Select label={i18n.yourIndustry} i18n={i18n} onSelectedIndustry={onSelectedIndustry} />
                      <SubmitButton type="submit" aria-label="Submit button" sending={sending}>
                        {sending ? <Sending /> : i18n.tourismTips}
                      </SubmitButton>
                    </form>
                    <ErrorMsg show={errorMsg !== null}>{errorMsg}</ErrorMsg>
                  </>
                )}
              </NewsletterWrapper>
            </Container>
          </NewsletterSection>
        </FooterContainer>
      )}
    </LocaleConsumer>
  )
}

Footer.propTypes = {
  location: PropTypes.object
}

Footer.defaultProps = {
  location: {
    search: ''
  }
}

export default Footer
