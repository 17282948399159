import React, { useState, useEffect } from 'react'

import {
  OverlayMenuWrapper,
  TryForFree,
  LogIn,
  NavItemUnclickable,
  NavList,
  NavListItem,
  NavLink,
  ExternalLink,
  NavChildMenu,
  NavItemWrapper,
  MenuFooter,
  Scroller
} from './styles'

import Button from '../Button'
import Locales from '../../constants/locales'
import MobileLanguagePicker from '../../components/Mobile-menu/MobileLangPicker'

import HotelIcon from '../../images/hotel-icon.png'
import HostelIcon from '../../images/hostel-icon.png'
import VacationRentalsIcon from '../../images/vacation-rentals-icon.png'
import PropertyMangaersIcon from '../../images/property-managers-icon.png'

import { handleTrackedRentlioWebappClick } from 'helper/url'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

const OverlayMenu = ({ isOpen, menus, locale, location }) => {
  const [showChildMenu, setShowChildMenu] = useState(false)
  const [showFeatures, setShowFeatures] = useState(false)
  const [showResources, setShowResources] = useState(false)
  const [showCompany, setShowCompany] = useState(false)
  const mainMenu = menus.filter(({ node }) => Locales.menuSwithcer[locale] === node.slug)

  const lang = locale === 'en' ? `/${locale}/` : '/'
  const category = locale === 'en' ? 'category' : 'kategorija'

  const mixpanel = useMixpanel()

  const handleChildMenu = (slug = '', e) => {
    const unclickables = document.querySelectorAll('#unclickable')
    unclickables.forEach((unclickable) => {
      if (unclickable.classList.contains('active')) {
        unclickable.classList.remove('active')
      }
    })

    if (slug === 'prednosti' || slug === 'features') {
      e.target.classList.toggle('active', !showFeatures)
      setShowFeatures(!showFeatures)
      setShowResources(false)
      setShowCompany(false)
      setShowChildMenu(!showChildMenu)
      return
    }

    if (slug === 'savjeti' || slug === 'resources') {
      e.target.classList.toggle('active', !showResources)
      setShowResources(!showResources)
      setShowFeatures(false)
      setShowCompany(false)
      setShowChildMenu(!showChildMenu)
      return
    }

    if (slug === 'o-nama' || slug === 'about-us') {
      e.target.classList.toggle('active', !showCompany)
      setShowCompany(!showCompany)
      setShowFeatures(false)
      setShowResources(false)
      setShowChildMenu(!showChildMenu)
      return
    }

    setShowChildMenu(!showChildMenu)
  }

  useEffect(() => {
    if (!isOpen) {
      setShowChildMenu(false)
      return
    }
  }, [isOpen])

  return (
    <>
      <OverlayMenuWrapper isOpen={isOpen}>
        <Scroller>
          <NavList>
            {mainMenu[0].node.items.map((item, i, array) => {
              if (item.classes === 'button') {
                return
              }
              if (item.classes === 'login') {
                return
              }
              return (
                <NavListItem key={item.object_id} unclickable={item.classes === 'unclickable'} show={showChildMenu}>
                  {item.classes === 'unclickable' ? (
                    <NavItemWrapper>
                      <NavItemUnclickable
                        id="unclickable"
                        show={showChildMenu}
                        nopadding="true"
                        onClick={(e) => handleChildMenu(item.object_slug, e)}
                      >
                        {item.title}
                      </NavItemUnclickable>
                      {showFeatures && (item.object_slug === 'prednosti' || item.object_slug === 'features') && (
                        <NavChildMenu addMargin={showFeatures}>
                          {item.wordpress_children.map((childItem, i) => {
                            if (childItem.object_slug === 'hotel') {
                              return (
                                <li key={`${childItem.object_slug}-${i}`}>
                                  <figure>
                                    <img src={HotelIcon} alt="Hotel" />
                                  </figure>
                                  <NavLink
                                    to={`${lang}${childItem.object_slug}${location.search}`}
                                    onClick={(e) =>
                                      handleTrackedRentlioWebappClick(
                                        e,
                                        '',
                                        '',
                                        mixpanel,
                                        'user_segment_clicked',
                                        'nav_menu',
                                        false,
                                        false,
                                        false,
                                        'hotel'
                                      )
                                    }
                                  >
                                    {childItem.title}
                                  </NavLink>
                                </li>
                              )
                            }

                            if (childItem.object_slug === 'hostel') {
                              return (
                                <li key={`${childItem.object_slug}-${i}`}>
                                  <figure>
                                    <img src={HostelIcon} alt="Hostel" />
                                  </figure>
                                  <NavLink
                                    to={`${lang}${childItem.object_slug}${location.search}`}
                                    onClick={(e) =>
                                      handleTrackedRentlioWebappClick(
                                        e,
                                        '',
                                        '',
                                        mixpanel,
                                        'user_segment_clicked',
                                        'nav_menu',
                                        false,
                                        false,
                                        false,
                                        'hostel'
                                      )
                                    }
                                  >
                                    {childItem.title}
                                  </NavLink>
                                </li>
                              )
                            }

                            if (
                              childItem.object_slug === 'mali-iznajmljivaci' ||
                              childItem.object_slug === 'vacation-rentals'
                            ) {
                              return (
                                <li key={`${childItem.object_slug}-${i}`}>
                                  <figure>
                                    <img src={VacationRentalsIcon} alt="Vacation Rentals" />
                                  </figure>
                                  <NavLink
                                    to={`${lang}${childItem.object_slug}${location.search}`}
                                    onClick={(e) =>
                                      handleTrackedRentlioWebappClick(
                                        e,
                                        '',
                                        '',
                                        mixpanel,
                                        'user_segment_clicked',
                                        'nav_menu',
                                        false,
                                        false,
                                        false,
                                        'vacation_rentals'
                                      )
                                    }
                                  >
                                    {childItem.title}
                                  </NavLink>
                                </li>
                              )
                            }

                            if (
                              childItem.object_slug === 'property-manageri' ||
                              childItem.object_slug === 'property-managers'
                            ) {
                              return (
                                <li key={`${childItem.object_slug}-${i}`}>
                                  <figure>
                                    <img src={PropertyMangaersIcon} alt="Multi property managers" />
                                  </figure>
                                  <NavLink
                                    to={`${lang}${childItem.object_slug}${location.search}`}
                                    onClick={(e) =>
                                      handleTrackedRentlioWebappClick(
                                        e,
                                        '',
                                        '',
                                        mixpanel,
                                        'user_segment_clicked',
                                        'nav_menu',
                                        false,
                                        false,
                                        false,
                                        'property_managers'
                                      )
                                    }
                                  >
                                    {childItem.title}
                                  </NavLink>
                                </li>
                              )
                            }

                            if (childItem.object_slug === 'property-management-system') {
                              return (
                                <li key={`${childItem.object_slug}-${i}`}>
                                  <NavLink
                                    to={`${lang}${childItem.object_slug}${location.search}`}
                                    onClick={(e) =>
                                      handleTrackedRentlioWebappClick(
                                        e,
                                        '',
                                        '',
                                        mixpanel,
                                        'feature_clicked',
                                        'nav_menu',
                                        false,
                                        true,
                                        false,
                                        '',
                                        'pms'
                                      )
                                    }
                                  >
                                    {childItem.title}
                                  </NavLink>
                                </li>
                              )
                            }

                            if (
                              childItem.object_slug === 'channel-manager' ||
                              childItem.object_slug === 'channel-manager-hrvatska'
                            ) {
                              return (
                                <li key={`${childItem.object_slug}-${i}`}>
                                  <NavLink
                                    to={`${lang}${childItem.object_slug}${location.search}`}
                                    onClick={(e) =>
                                      handleTrackedRentlioWebappClick(
                                        e,
                                        '',
                                        '',
                                        mixpanel,
                                        'feature_clicked',
                                        'nav_menu',
                                        false,
                                        true,
                                        false,
                                        '',
                                        'channel_manager'
                                      )
                                    }
                                  >
                                    {childItem.title}
                                  </NavLink>
                                </li>
                              )
                            }

                            if (childItem.object_slug === 'mobilna-aplikacija' || item.object_slug === 'mobile-apps') {
                              return (
                                <li key={`${childItem.object_slug}-${i}`}>
                                  <NavLink
                                    to={`${lang}${childItem.object_slug}${location.search}`}
                                    onClick={(e) =>
                                      handleTrackedRentlioWebappClick(
                                        e,
                                        '',
                                        '',
                                        mixpanel,
                                        'feature_clicked',
                                        'nav_menu',
                                        false,
                                        true,
                                        false,
                                        '',
                                        'mobile_app'
                                      )
                                    }
                                  >
                                    {childItem.title}
                                  </NavLink>
                                </li>
                              )
                            }

                            if (childItem.object_slug === 'booking-engine') {
                              return (
                                <li key={`${childItem.object_slug}-${i}`}>
                                  <NavLink
                                    to={`${lang}${childItem.object_slug}${location.search}`}
                                    onClick={(e) =>
                                      handleTrackedRentlioWebappClick(
                                        e,
                                        '',
                                        '',
                                        mixpanel,
                                        'feature_clicked',
                                        'nav_menu',
                                        false,
                                        true,
                                        false,
                                        '',
                                        'booking_engine'
                                      )
                                    }
                                  >
                                    {childItem.title}
                                  </NavLink>
                                </li>
                              )
                            }

                            return (
                              <li key={`${childItem.object_slug}-${i}`}>
                                <NavLink
                                  to={`${lang}${childItem.object_slug}${location.search}`}
                                  onClick={(e) =>
                                    handleTrackedRentlioWebappClick(
                                      e,
                                      '',
                                      '',
                                      mixpanel,
                                      'feature_clicked',
                                      'nav_menu',
                                      false,
                                      true,
                                      false,
                                      '',
                                      childItem.title
                                    )
                                  }
                                >
                                  {childItem.title}
                                </NavLink>
                              </li>
                            )
                          })}
                        </NavChildMenu>
                      )}
                      {showResources && (item.object_slug === 'savjeti' || item.object_slug === 'resources') && (
                        <NavChildMenu>
                          {item.wordpress_children.map((childItem, i) => {
                            if (childItem.classes === 'customer-success') {
                              return (
                                <li key={`${childItem.object_slug}-${i}`}>
                                  <NavLink
                                    to={`${lang}${category}${childItem.url}${location.search}`}
                                    onClick={(e) =>
                                      handleTrackedRentlioWebappClick(
                                        e,
                                        '',
                                        '',
                                        mixpanel,
                                        'case_study_clicked',
                                        'nav_menu',
                                        false,
                                        true
                                      )
                                    }
                                  >
                                    {childItem.title}
                                  </NavLink>
                                </li>
                              )
                            }

                            if (childItem.object_slug === 'blog') {
                              return (
                                <li key={`${childItem.object_slug}-${i}`}>
                                  <NavLink
                                    to={`${lang}${item.wordpress_children[0].object_slug}${location.search}`}
                                    onClick={(e) =>
                                      handleTrackedRentlioWebappClick(
                                        e,
                                        '',
                                        '',
                                        mixpanel,
                                        'blog_section_clicked',
                                        'nav_menu',
                                        false,
                                        true
                                      )
                                    }
                                  >
                                    {childItem.title}
                                  </NavLink>
                                </li>
                              )
                            }

                            if (childItem.classes === 'api') {
                              return (
                                <li key={`${childItem.object_slug}-${i}`}>
                                  <ExternalLink href={childItem.url} target="__blank">
                                    {childItem.title}
                                  </ExternalLink>
                                </li>
                              )
                            }

                            if (childItem.classes === 'help') {
                              return (
                                <li key={`${childItem.object_slug}-${i}`}>
                                  <ExternalLink href={childItem.url} target="__blank">
                                    {childItem.title}
                                  </ExternalLink>
                                </li>
                              )
                            }
                            return (
                              <li key={`${childItem.object_slug}-${i}`} className={showResources ? 'open' : ''}>
                                <NavLink to={`${lang}${childItem.object_slug}${location.search}`}>
                                  {childItem.title}
                                </NavLink>
                              </li>
                            )
                          })}
                        </NavChildMenu>
                      )}
                      {showCompany && (item.object_slug === 'o-nama' || item.object_slug === 'about-us') && (
                        <NavChildMenu>
                          {item.wordpress_children.map((childItem, i) => {
                            if (childItem.classes === 'year') {
                              return (
                                <li key={`${childItem.object_slug}-${i}`}>
                                  <ExternalLink href={childItem.url} target="__blank">
                                    {childItem.title}
                                  </ExternalLink>
                                </li>
                              )
                            }
                            return (
                              <li key={`${childItem.object_slug}-${i}`}>
                                <NavLink to={`${lang}${childItem.object_slug}${location.search}`}>
                                  {childItem.title}
                                </NavLink>
                              </li>
                            )
                          })}
                        </NavChildMenu>
                      )}
                    </NavItemWrapper>
                  ) : (
                    <NavLink to={`${lang}${item.object_slug}${location.search}`} activeClassName="" nopadding="true">
                      {item.title}
                    </NavLink>
                  )}
                </NavListItem>
              )
            })}
            <MobileLanguagePicker locale={locale} />
          </NavList>
          <MenuFooter>
            {mainMenu[0].node.items.map((item, i, array) => {
              if (item.classes === 'button') {
                return (
                  <TryForFree key={item.object_id}>
                    <Button
                      href={`${item.url}${location.search}`}
                      type="large"
                      target="__blank"
                      onClick={(e) =>
                        handleTrackedRentlioWebappClick(
                          e,
                          item.url,
                          location.search,
                          mixpanel,
                          'try_free_button_clicked',
                          'nav_menu',
                          true,
                          true,
                          true
                        )
                      }
                    >
                      {item.title}
                    </Button>
                  </TryForFree>
                )
              }
              if (item.classes === 'login') {
                return (
                  <LogIn key={item.object_id}>
                    <Button
                      href={`${item.url}${location.search}`}
                      type="transparent"
                      target="__blank"
                      onClick={(e) =>
                        handleTrackedRentlioWebappClick(
                          e,
                          item.url,
                          location.search,
                          mixpanel,
                          'log_in_clicked',
                          'nav_menu',
                          true,
                          true,
                          true
                        )
                      }
                    >
                      {item.title}
                    </Button>
                  </LogIn>
                )
              }
            })}
          </MenuFooter>
        </Scroller>
      </OverlayMenuWrapper>
    </>
  )
}

OverlayMenu.defaultProps = {
  location: {
    search: ''
  }
}

export default OverlayMenu
