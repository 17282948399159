/**
 * Truncate String after N characters
 * @param {string} text
 * @param {number} charLength
 */

export const truncateString = (text, charLength = 100) => {
  return text.length > charLength ? text.substring(0, text.length - charLength) + '...' : text
}

/**
 * Format Currency depending on locale
 * @param {number} number
 * @param {string} locale
 */
export const formatCurrency = (number, locale) => {
  const domesticCurrency = new Intl.NumberFormat('hr-HR', {
    style: 'currency',
    currency: 'HRK',
    minimumFractionDigits: 2
  })

  const foreignCurrency = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  })

  if (locale !== 'hr') {
    return foreignCurrency.format(number)
  }
  return domesticCurrency.format(number)
}

export const formatNumber = (number) => {
  return new Intl.NumberFormat().format(number)
}

/**
 * Format Date depending on locale
 * @param {string} date
 * @param {string} locale
 */
export const formatDate = (dateString, locale) => {
  let date = new Date(dateString)

  const domesticDate = new Intl.DateTimeFormat('hr-HR', { year: 'numeric', month: 'long', day: 'numeric' })
  const foreignDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' })

  if (locale !== 'hr') {
    return foreignDate.format(date)
  }
  return domesticDate.format(date)
}

/**
 * Convert Date to Timestamp
 * @param {string} date
 */
export const toTimestamp = (date) => new Date(date).getTime()

/**
 * Property Price Calculation
 * @param {*} numberOfUnits
 * @param {*} channelManager
 * @param {*} currency
 */
export const priceCalculation = (numberOfUnits, channelManager, isHostel, currency = 'HRK') => {
  let basicPrice, result
  const currencyRate = 7.5345

  basicPrice = currency === 'HRK' ? 45.207 : 45.207 / currencyRate

  // if (isHostel) {
  //   basicPrice = currency === 'HRK' ? 15.069 : 15.069 / currencyRate
  // }
  if (isHostel) {
    basicPrice = currency === 'HRK' ? 11.302 : 11.302 / currencyRate
  }

  // if (channelManager && isHostel) {
  //   basicPrice = currency === 'HRK' ? 30.138 : 30.138 / currencyRate
  // }
  if (channelManager && isHostel) {
    basicPrice = currency === 'HRK' ? 22.603 : 22.603 / currencyRate
  }

  if (channelManager && !isHostel) {
    basicPrice = currency === 'HRK' ? 90.414 : 90.414 / currencyRate
  }

  if (isHostel) {
    result = Number(numberOfUnits * basicPrice).toFixed(2)
  } else {
    // from 1 - 15 price is 30
    if (Number(numberOfUnits) <= 3) {
      result = Number(numberOfUnits * basicPrice).toFixed(2)
    } else if (Number(numberOfUnits) >= 4 && Number(numberOfUnits) <= 15) {
      // from 16 - 30 price is with discount 45% (30*(1-45%)) => 16.5
      result = Number(numberOfUnits * (basicPrice - basicPrice * 0.166666666)).toFixed(2)
    } else if (Number(numberOfUnits) >= 16 && Number(numberOfUnits) <= 30) {
      // from 31 - 50 price is with discount 50% (30*(1-50%)) => 15
      result = Number(numberOfUnits * (basicPrice - basicPrice * 0.3333333333)).toFixed(2)
    } else if (Number(numberOfUnits) >= 31 && Number(numberOfUnits) <= 50) {
      // from 31 - 50 price is with discount 50% (30*(1-50%)) => 15
      result = Number(numberOfUnits * (basicPrice - basicPrice * 0.416666666)).toFixed(2)
    }
  }

  // return final price
  return result
}
// ---OLD PRICING hr/eur-----:

// export const priceCalculation = (numberOfUnits, channelManager, isHostel, currency = 'HRK') => {
//   let basicPrice, result
//   const currencyRate = 7.5

//   basicPrice = currency === 'HRK' ? 30 : 30 / currencyRate

//   if (isHostel) {
//     basicPrice = currency === 'HRK' ? 10 : 10 / currencyRate
//   }

//   if (channelManager && isHostel) {
//     basicPrice = currency === 'HRK' ? 20 : 20 / currencyRate
//   }

//   if (channelManager && !isHostel) {
//     basicPrice = currency === 'HRK' ? 60 : 60 / currencyRate
//   }

//   // from 1 - 15 price is 30
//   if (Number(numberOfUnits) <= 15) {
//     result = Number(numberOfUnits * basicPrice).toFixed(2)
//   } else if (Number(numberOfUnits) >= 16 && Number(numberOfUnits) <= 30) {
//     // from 16 - 30 price is with discount 45% (30*(1-45%)) => 16.5
//     result = 15 * basicPrice + Number(numberOfUnits - 15) * (basicPrice - basicPrice * 0.45)
//   } else if (Number(numberOfUnits) >= 31 && Number(numberOfUnits) <= 50) {
//     // from 31 - 50 price is with discount 50% (30*(1-50%)) => 15
//     result =
//       15 * basicPrice +
//       15 * (basicPrice - basicPrice * 0.45) +
//       Number(numberOfUnits - 30) * (basicPrice - basicPrice * 0.5)
//   }

//   // return final price
//   return result
// }

/**
 * Scroll to desired element on page using quadratic easing in/out
 * @param {object} element
 * @param {number} to
 * @param {number} duration
 */
export const scrollTo = (element, to, duration) => {
  let start = element.scrollTop,
    change = to - start,
    currentTime = 0,
    increment = 20

  //t = current time
  //b = start value
  //c = change in value
  //d = duration
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2
    if (t < 1) return (c / 2) * t * t + b
    t--
    return (-c / 2) * (t * (t - 2) - 1) + b
  }

  let animateScroll = function () {
    currentTime += increment
    let val = parseInt(easeInOutQuad(currentTime, start, change, duration))
    element.scrollTop = val
    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    }
  }

  animateScroll()
}

/**
 * Average speed to read the blog post
 * @param content
 * @returns {number} - Time in minutes
 */

export const timeToRead = (content) => {
  const averageReadSpeed = 240
  const countWords = content.split(/\W+/).length
  let timeToReadResult = Math.round(countWords / averageReadSpeed)
  if (timeToReadResult === 0) {
    timeToReadResult = 1
  }
  return timeToReadResult + ' min'
}

/**
 * Validate Numeric Input - only numbers allowed
 * @param event
 * @returns {Boolean}
 */

export const validateNumericInput = (e) => {
  const numericKeyCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]

  const numericInputHelper = (e) => {
    const keyCode = e.keyCode || e.which
    const result = numericKeyCodes.findIndex((k) => k === keyCode)
    return result
  }

  if (numericInputHelper(e) !== -1) {
    return true
  }

  e.preventDefault()
  return false
}
