import React from 'react'
import { GroupWrapper, FormInputContainer, FormInputLabel } from './styles'

const FormInput = ({ children, handleChange, label, ...otherProps }) => (
  <GroupWrapper>
    <FormInputContainer onChange={handleChange} {...otherProps} id={otherProps.name} />
    {label ? (
      <FormInputLabel
        className={otherProps.value.length ? 'shrink' : ''}
        lightGrey={otherProps.lightGrey}
        active={otherProps.active}
        htmlFor={otherProps.name}
      >
        {label}
      </FormInputLabel>
    ) : null}
    {children}
  </GroupWrapper>
)

export default FormInput
