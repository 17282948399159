import React from 'react'
import Img from 'gatsby-image'
import { EuVisiblityWrapper, ImagesWrapper } from './styles'

import { useEuVisibilityImages } from '../../../graphql/useEuVisibilityImagesHooks'

const EuVisibility = () => {

  const {Esif, Eu, Hamag} = useEuVisibilityImages()
  return (
    <EuVisiblityWrapper>
      <p>
        Uslijed krize uzrokovane pandemijom korona virusa, a u svrhu očuvanja radnih mjesta, tvrtka je 2020. godine
        podignula kredit Hrvatske agencije za malo gospodarstvo, inovacije i investicije. Rentlio d.o.o je krajnji
        primatelj financijskog instrumenta sufinanciranog iz Europskog fonda za regionalni razvoj u sklopu operativnog
        programa “Konkurentnost i kohezija”.
      </p>
      <ImagesWrapper>
        <figure>
          <Img fluid={Esif.childImageSharp.fluid} alt="ESIF" />
        </figure>
        <figure>
          <Img fluid={Eu.childImageSharp.fluid} alt="EU amblem" />
        </figure>
        <figure>
          <Img fluid={Hamag.childImageSharp.fluid} alt="HAMAG Bicro" />
        </figure>
      </ImagesWrapper>
    </EuVisiblityWrapper>
  )
}

export default EuVisibility
