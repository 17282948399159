import styled from 'styled-components'
import { Link } from 'gatsby'

export const NavigationWrapper = styled.nav`
  display: none;
  align-items: center;
  justify-content: space-between;
  ${({ isHeadroom, mode, theme }) =>
    isHeadroom
      ? `color: ${theme.colors.veryDarkBlue};`
      : `color: ${mode === 'light' ? theme.colors.pureWhite : theme.colors.veryDarkBlue};`}

  @media (min-width: 1200px) {
    display: flex;
  }
`

export const NavList = styled.ul`
  display: flex;
  align-items: center;

  > li {
    padding: 0rem 1.38rem 0rem 1.38rem;
  }

  > li:nth-of-type(6) {
    padding: 0;
  }

  > li:last-child {
    padding: 0rem 2.14rem 0rem 0.8rem;
    ${({ isHeadroom, mode, theme }) =>
      isHeadroom
        ? `color: ${theme.colors.veryDarkBlue};`
        : `color: ${mode === 'light' ? theme.colors.pureWhite : theme.colors.vividBlue};`}

    a {
      padding: 0;
    }
  }

  li:hover > .dropdown-content {
    visibility: visible;
    opacity: 1;
  }
`

export const NavLink = styled(Link)`
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  padding: ${({ nopadding }) => (nopadding ? `0` : `0.5rem 1.38rem 2rem 1.38rem`)};
  position: relative;

  &:hover {
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 8px;
      background-color: ${({ color, theme }) => (color ? color : theme.colors.vividBlue)};
      ${({ opacity }) => (opacity ? `opacity:${opacity};` : null)}
      bottom: -2px;
      left: 0;
      z-index: -1;
    }
  }
`

export const SearchItem = styled.li`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.vividBlue};
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0 1.7rem;
  cursor: pointer;

  .search-icon {
    margin-left: 0.5rem;
  }
`

export const ExternalLink = styled.a`
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  padding: ${({ nopadding }) => (nopadding ? `none` : `0.5rem 1.38rem 2rem 1.38rem`)};
  position: relative;

  &:hover {
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 8px;
      background-color: ${({ color, theme }) => (color ? color : theme.colors.vividBlue)};
      ${({ opacity }) => (opacity ? `opacity:${opacity};` : null)}
      bottom: -2px;
      left: 0;
      z-index: -1;
    }
  }
`

export const NavItemUnclickable = styled.span`
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
`
export const NavigationDropdown = styled.div`
  position: absolute;
  display: flex;
  visibility: hidden;
  width: 100%;
  max-width: 1200px;
  left: 0;
  top: 80%;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin: 0 auto;
  padding: 1.4rem 0;
  opacity: 0;
  transition: visibility 0.25s ease-in-out, opacity 0.25s ease-in-out;
  z-index: 10;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -13px;
    border: 1px solid #eaeaea;
    border-bottom: 0;
    border-right: 0;
    box-shadow: -15px -20px 20px 0px rgba(0, 0, 0, 0.05);
    border-radius: 11px;
    background: #ffffff;
    border-top-width: 0px;
    border-left-width: 0px;
    width: 3rem;
    height: 3rem;
    transform: ${({ position }) => `translateX(${position}px) rotate(45deg)`};
    transform-origin: center;
    z-index: -1;
  }
`
export const Row = styled.div`
  display: flex;
  justify-content: center;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-basis: ${({ width }) => `${width}%`};
`

export const NavItemBox = styled.div`
  display: flex;
  align-items: ${({ align }) => align};
  flex-direction: ${({ direction }) => direction};
  flex-basis: 45%;

  h6 {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-family: 'Barlow';
    font-size: 1.71rem;
    font-weight: 800;
    line-height: 2.14rem;
    margin-bottom: 0.35rem;
    margin-top: 2.14rem;
    cursor: pointer;
  }

  span {
    display: inline-block;
    width: 100%;
    padding-right: 5rem;
    color: ${({ theme }) => theme.colors.darkGrey};
    line-height: 1.71rem;
  }

  figure {
    width: ${({ width }) => (width ? `${width}px` : '320px')};

    margin-right: 2rem;

    img {
      border-radius: 10px;
    }
  }

  > a {
    display: flex;
    align-items: ${({ align }) => align};
    flex-direction: ${({ direction }) => direction};
    text-transform: none;

    &:hover {
      &::after {
        display: none;
      }

      h6 {
        color: ${({ theme }) => theme.colors.vividBlue};
      }
    }
  }
`

export const ListWrapper = styled.div`
  display: flex;
  padding: 3rem 0 3rem 2rem;
  border-radius: 0 20px 20px 0;
`
export const List = styled.ul`
  display: flex;
  flex-direction: column;
`
export const ListItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 1.28rem;
  line-height: 1.5rem;
  padding: 1rem 0;
  cursor: pointer;

  a {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    text-transform: capitalize;
    font-weight: 900;
  }

  svg {
    margin-right: 1rem;
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.vividBlue};

    a {
      color: ${({ theme }) => theme.colors.veryDarkBlue};
    }

    a::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 8px;
      background-color: ${({ theme }) => theme.colors.vividBlue};
      opacity: 0.3;
      bottom: -2px;
      left: 0;
    }
    svg {
      transform: translateX(5px);
    }
  }
`
