import styled, { css } from 'styled-components'

const animateLabel = css`
  top: 0px;
  font-size: 12px;
  ${({ active, grey, lightGrey, theme }) =>
    active
      ? `color:${theme.colors.veryDarkBlue};`
      : `color: ${grey ? theme.colors.veryDarkBlue : lightGrey ? theme.colors.lightGrey : theme.colors.lightestGrey};`}
`

export const GroupWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 2rem auto;
  /* flex-basis: 100%; */

  @media (max-width: 1024px) {
    width: 90%;
    margin: 1rem 2rem;
  }
`
export const FormInputContainer = styled.input`
  background: none;
  background-color: ${({ transparent, theme }) => (transparent ? 'transparent' : `${theme.colors.veryDarkBlue}`)};
  ${({ active, grey, theme }) =>
    active
      ? `color:${theme.colors.veryDarkBlue};`
      : `color: ${grey ? theme.colors.lightGrey : theme.colors.lightestGrey};`}
  font-size: 1.42rem;
  line-height: 1.71rem;
  padding: 8px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid
    ${({ active, grey, theme }) =>
      active ? theme.colors.veryDarkBlue : grey ? theme.colors.lightGrey : theme.colors.veryLightGray};
  margin: 1.78rem 0;

  &:focus {
    outline: none;
    color: ${({ grey, theme }) => (grey ? theme.colors.veryDarkBlue : theme.colors.lightestGrey)};
    border-bottom: 1px solid ${({ grey, theme }) => (grey ? theme.colors.veryDarkBlue : theme.colors.veryLightGray)};
  }

  &:focus ~ label {
    ${animateLabel}
  }
`
export const FormInputLabel = styled.label`
  ${({ active, lightGrey, theme }) =>
    active
      ? `color:${theme.colors.veryDarkBlue};`
      : `color: ${lightGrey ? theme.colors.lightGrey : theme.colors.lightestGrey};`}

  font-family: 'Barlow';
  font-size: 1.42rem;
  font-weight: 600;
  line-height: 1.71rem;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 30px;
  transition: 300ms ease all;

  &.shrink {
    ${animateLabel}
  }

  @media only screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`
