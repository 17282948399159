import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'

import { LangPickerWrapper, MobileLangPick, NavLink} from './styles'

import useLanguageMapping from 'graphql/useLangaugeMapping'

const language = {
  hr: 'hr',
  en: 'en'
}

const LangPicker = ({ locale, mode, isHeadroom, location }) => {

  const [selectedLang, setSelectedLang] = useState('hr')
  const { languagePagesMapping, languagePostsMapping, languagePostsCategoriesMapping } = useLanguageMapping()
  const removeTrailing = (path) => (path === `/` ? path : path.replace(/\/$/, ``))

  const handleSelectLang = (e, lang) => {
    handleRouteChange(lang)
    setSelectedLang(lang)
  }

useEffect(() => {
    setSelectedLang(locale)
}, [locale])

  function handleRouteChange(lang) {
    const getUrl = window.location.pathname
    const getSlug = window.location.pathname.split('/').pop()

    const isBlogPost = languagePostsMapping.some((item) => {
      return item.slug === getSlug
    })

    const isBlogPostCategory = languagePostsCategoriesMapping.some((item) => {
      return item.slug === getSlug
    })

    if (isBlogPost) {
      const currentPostWpID = languagePostsMapping.find((item) => {
        return item.slug === getSlug
      })

      const getTranslationPostRoute = languagePostsMapping
        .filter((item) => item.lang === language[lang])
        .find((i) => currentPostWpID.translations[lang] === i.wordpress_id)

      const prefixPath =
        lang === 'en' ? `/en/blog/${getTranslationPostRoute.slug}` : `/blog/${getTranslationPostRoute.slug}`
      return navigate(removeTrailing(prefixPath))
    }

    if (isBlogPostCategory) {
      const currentPostCategoryWpID = languagePostsCategoriesMapping.find((item) => {
        return item.slug === getSlug
      })

      const getTranslationPostRoute = languagePostsCategoriesMapping
        .filter((item) => item.lang === language[lang])
        .find((i) => currentPostCategoryWpID.translations[lang] === i.wordpress_id)

      const prefixPath =
        lang === 'en' ? `/en/category/${getTranslationPostRoute.slug}` : `/kategorija/${getTranslationPostRoute.slug}`
      return navigate(removeTrailing(prefixPath))
    }

    const currentPageWpID = languagePagesMapping.find((item) => {
      return removeTrailing(item.path) === `${removeTrailing(getUrl)}`
    })

    const getTranslationRoute = languagePagesMapping
      .filter((item) => item.lang === language[lang])
      .find((i) => currentPageWpID.translations[lang] === i.wordpress_id)

      
    return navigate(removeTrailing(getTranslationRoute.path))
  }
  return (
    <LangPickerWrapper >
                 <MobileLangPick>
              <NavLink  className={locale === 'hr' ? 'blueLink' : null} onClick={(e) => handleSelectLang(e, 'hr')} >
                HR
              </NavLink>
              |
              <NavLink  className={locale === 'en' ? 'blueLink' : null} onClick={(e) => handleSelectLang(e, 'en' )}>
                EN
              </NavLink>
            </MobileLangPick>
    </LangPickerWrapper>
  )
}

LangPicker.defaultProps = {
  locale: 'hr',
  location: {
    search: ''
  }
}

export default LangPicker
