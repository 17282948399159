import { navigate } from 'gatsby'

export const handleRentlioWebappClick = (e, url, query) => {
  e.preventDefault()
  e.stopPropagation()

  navigate(`${url}${query}`)
}

export const handleTrackedRentlioWebappClick = (
  e,
  url,
  query,
  mixpanel,
  eventName,
  location,
  regularLink = false,
  disableNavigate = false,
  outsideLink = false,
  type = '',
  feature=''
) => {
  if (regularLink) {
    e.preventDefault()
    e.stopPropagation()
  }

  if (eventName == 'try_free_button_clicked' || eventName == 'log_in_clicked' || eventName == 'contact_form_submitted' || eventName == 'newsletter_signed_up') {
    window.dataLayer && window.dataLayer.push({
      'event': eventName
    });
  }

  if (outsideLink) {
    mixpanel.track(eventName, { category: 'website', location })
    window.open(`${url}${query}`)
    return
  }

  if (eventName == 'blog_post_shared') {
    mixpanel.track(eventName, { category: 'website', medium: location })
    return
  }

  if (type !== '') {
    mixpanel.track(eventName, { category: 'website', location, type })
  } else if (feature === 'pms' ||  feature === 'channel_manager' || feature === 'mobile_app' || feature === 'booking_engine') {
    mixpanel.track(eventName, { category: 'website', location, feature_name: feature })
  } else {
    mixpanel.track(eventName, { category: 'website', location })
  }

  if (disableNavigate) return
  navigate(`${url}${query}`)
}

export const handleTrackedBookingEngineExamplesClick = (
    e,
    url,
    query,
    mixpanel,
    eventName,
    category
) => {

  e.preventDefault()
  e.stopPropagation()
    mixpanel.track(eventName, { category: 'website', booking_engine_category: category })
    window.open(`${url}${query}`)
    return
}