import React from 'react'

const ArrowIcon = ({ className, mode, isHeadroom }) => {
  return (
    <svg className={className} width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.13333 0H12L6.13333 6L0 0H6.13333Z" fill={mode === 'dark' || isHeadroom ? '#C5C5C5' : '#fff'} />
    </svg>
  )
}

const defaultProps = {
  fill: '#C5C5C5'
}

ArrowIcon.defaultProps = defaultProps

export default ArrowIcon
