import styled from 'styled-components'

export const EuVisiblityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;

  p {
    color: ${({ theme }) => theme.colors.darkGrey};
    line-height: 1.5;
    width: 65%;
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    p {
      width: 100%;
      margin-bottom: 2rem;
      text-align: center;
    }
  }
`
export const ImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;

  figure {
    width: 50%;

    :nth-of-type(2) {
      width: 30%;
    }
  }

  @media (max-width: 1024px) {
    width: 65%;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`
